export const navItems = [
  {
    id: "shippers",
    title: "Shippers",
    url: "/shippers",
    submenu: [
      {
        title: "Vehicle Services",
        url: "/shippers/vehicles",
      },
      {
        title: "Freight Services",
        url: "/shippers/freight",
      },
      {
        title: "RPM Moves",
        url: "/individual",
      },
    ],
  },
  {
    id: "carriers",
    title: "Carriers",
    url: "/carriers",
  },
  {
    id: "individual",
    title: "Individual",
    url: "/individual",
    submenu: [
      {
        title: "RPM Moves",
        url: "/individual",
      },
      {
        title: "Track My Shipment",
        url: "/individual/track-my-shipment",
      },
      {
        title: "FAQs",
        url: "/individual/faq",
      },
    ],
  },
  {
    id: "about",
    title: "About",
    url: "/about-us",
    submenu: [
      {
        title: "Culture",
        url: "/culture",
      },
      {
        title: "Careers",
        url: "/careers",
      },
    ],
  },
  {
    id: "resources",
    title: "Resources",
    submenu: [
      {
        title: "News",
        url: "/news",
      },
      {
        title: "Blog",
        url: "/blog",
      },
    ],
  },
  {
    id: "technology",
    title: "Technology",
    url: "/technology",
  },
];
