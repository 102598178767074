import React from "react";
import Box from "@mui/material/Box";
import { Container, Grid, Typography, Button } from "@mui/material";
import PlayButton from "../components/PlayButton/PlayButton";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeroBox = styled(Box)(({ theme }) => ({
  height: 606,
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    height: "auto",
    padding: "0",
  },
}));

const HeroBoxTop = styled(Box)(({ theme }) => ({
  margin: "72px 0 0 0",
  [theme.breakpoints.down("lg")]: {
    margin: 0,
    padding: "68px 24px 76px",
    height: "auto",
  },
}));

const AboutHero = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        overflow: "hidden",
        backgroundImage: {
          xs: "none",
          lg: `url(${"/assets/about-hero-sail.webp"})`,
        },
        backgroundSize: { xs: "auto 380px", lg: "2400px auto" },
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 0",
      }}
    >
      <Container disableGutters={useMediaQuery(theme.breakpoints.down("md"))}>
        <HeroBox>
          <Grid
            container
            sx={{ flexDirection: { xs: "column-reverse", lg: "row" } }}
          >
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                display: { xs: "flex", lg: "block" },
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: { xs: "#0b1232", lg: "transparent" },
                backgroundImage: {
                  xs: `url(${"/assets/about-sash.webp"})`,
                  lg: "none",
                },
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto 380px",
                backgroundPosition: "50% 100%",
              }}
            >
              <HeroBoxTop>
                <Typography
                  variant="h2"
                  component="p"
                  sx={{
                    fontStyle: "italic",
                    fontWeight: 700,
                    color: "#ffffff",
                    mt: { xs: 0, lg: 19 },
                  }}
                >
                  About RPM
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ mt: 3, color: "#fff", maxWidth: "421px" }}
                >
                  Since our inception, we have maintained a people-first
                  approach, forging meaningful relationships with shippers,
                  carriers, and employees.
                </Typography>
                <Button
                  variant="outlined"
                  disableElevation
                  href="/culture"
                  sx={{ mt: 2 }}
                >
                  Get to know us
                </Button>
              </HeroBoxTop>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  height: { xs: "338px", lg: "606px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src="/assets/about-hero.webp"
                  alt="About RPM"
                  sx={{
                    width: "auto",
                    height: "606px",
                    position: "absolute",
                    right: "-284px",
                    [theme.breakpoints.down("lg")]: {
                      height: "338px",
                      right: "50%",
                      transform: "translateX(50%)",
                    },
                  }}
                />
                <PlayButton videoID="hy57QEVpG90" channel="youtube" />
              </Box>
            </Grid>
          </Grid>
        </HeroBox>
      </Container>
    </Box>
  );
};

export default AboutHero;
