import React from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Typography, Container } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import PlayButton from "../components/PlayButton/PlayButton";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeroBox = styled(Box)(({ theme }) => ({
  height: 492,
  backgroundImage: `url(${"/assets/shippers-case-studies.webp"})`,
  backgroundSize: "auto 492px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0 0",
  position: "relative",
  marginBottom: "56px",
  [theme.breakpoints.down("lg")]: {
    backgroundSize: "auto 240px",
    height: "auto",
    padding: "0",
    backgroundPosition: "50% 100%",
  },
}));

const HeroBoxTop = styled(Box)(({ theme }) => ({
  margin: "72px 0 0 0",
  maxWidth: "430px",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
    margin: 0,
    padding: "56px 24px",
    height: "auto",
  },
}));

const ShippersCaseStudies = () => {
  const theme = useTheme();
  return (
    <Container
      disableGutters={useMediaQuery(theme.breakpoints.down("md"))}
      maxWidth="xl"
    >
      <HeroBox>
        <Grid
          container
          sx={{ flexDirection: { xs: "column-reverse", lg: "row" } }}
        >
          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{ height: "240px", display: "flex", alignItems: "center" }}
            >
              <PlayButton videoID="XQrtn7GIEK0" channel="youtube" />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <HeroBoxTop>
              <Typography variant="body2" component="p">
                RPM Case Studies
              </Typography>
              <Typography
                variant="h2"
                component="p"
                sx={{
                  fontStyle: "italic",
                  fontWeight: 700,
                  mt: 4,
                }}
              >
                Transporting Deep Weather Testing Vehicles for Rivian
              </Typography>
              <Typography variant="body1" component="p" sx={{ mt: 3 }}>
                In the dead of winter, RPM was asked to transport deep weather
                testing vehicles to Fort Greely, Alaska for one of our largest
                OEM customers, Rivian. Check out the video to learn how we
                successfully picked up and transported the vehicles across “The
                Last Frontier.”
              </Typography>
              <Button
                href="/contact"
                variant="text"
                endIcon={<ChevronRightRoundedIcon />}
                sx={{
                  pl: 0,
                  mt: 2,
                  height: "24px",
                  color: "neutral.grey400",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                }}
                disableElevation
              >
                Request a Quote
              </Button>
            </HeroBoxTop>
          </Grid>
        </Grid>
      </HeroBox>
    </Container>
  );
};

export default ShippersCaseStudies;
