import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";

const FooterLink = (props) => {
  return (
    <Link
      href={props.url}
      underline="hover"
      color="neutral.grey400"
      sx={{
        display: "inline-flex",
        width: { xs: "50%", lg: "100%" },
      }}
    >
      <Typography variant="body2" sx={{ my: 1, pr: 1 }} component="span">
        {props.label}
      </Typography>
    </Link>
  );
};

export default FooterLink;
