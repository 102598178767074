import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";

const BulletList = (props) => {
  const items = props.items;
  return (
    <List
      sx={{
        listStyleType: props.type ?? "disc",
        pl: 3,
        my: 2,
        "& .MuiListItem-root": {
          display: "list-item",
        },
        ...props.sx,
      }}
    >
      {items.map((item, index) => (
        <ListItem sx={{ p: 0, fontFamily: "roboto" }} key={index}>
          <ListItemText variant="body1">{item}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default BulletList;
