import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Collapse,
  Typography,
} from "@mui/material";
import Award from "../components/Award/Award";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "react-reveal/Fade";

const awards = [
  {
    image: "/assets/award-1.webp",
    altText: "Transport Topics Top 100 award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-2.webp",
    altText: "ISO Award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-16.webp",
    altText: "ISO Award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-3.webp",
    altText: "Inc. 5000 award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-4.webp",
    altText: "Web Marketing Association award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-5.webp",
    altText: "Top Places Detroit Press award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-6.webp",
    altText: "Top Places USA award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-7.webp",
    altText: "Sustainability award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-8.webp",
    altText: "Transport Topics Top Freight Brokerage award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-9.webp",
    altText: "Inc. 5000 Honor Roll award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-10.webp",
    altText: "Auto Remarketing award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-11.webp",
    altText: "Crain's Detroit Business Private 200 award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-12.webp",
    altText: "dot COMM award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-13.webp",
    altText: "Crain's Fast 50 award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-14.webp",
    altText: "Food Logistics award",
    width: "174px",
    height: "166px",
  },
  {
    image: "/assets/award-15.webp",
    altText: "Great Place to Work award",
    width: "174px",
    height: "166px",
  },
];

const AwardsRecognitions = () => {
  const [showMore, setShowMore] = useState(false);
  const handleChange = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <Container>
      <Typography
        variant="h2"
        sx={{
          fontStyle: "italic",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 9,
          textAlign: "center",
        }}
      >
        Global Awards & Recognitions
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          my: 3,
          flexWrap: "wrap",
        }}
      >
        {awards.slice(0, 6).map((award, i) => (
          <Fade bottom delay={100 * i} key={i}>
            <Award
              image={award.image}
              altText={award.altText}
              width={award.width}
              height={award.height}
            />
          </Fade>
        ))}
      </Box>
      {awards.length > 6 && (
        <>
          <Collapse in={showMore}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 3,
                flexWrap: "wrap",
              }}
            >
              {awards.slice(6).map((award, i) => (
                <Award
                  key={i}
                  image={award.image}
                  altText={award.altText}
                  width={award.width}
                  height={award.height}
                />
              ))}
            </Box>
          </Collapse>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 9,
            }}
          >
            <Divider sx={{ width: { xs: "90%", sm: "50%" } }}>
              <Button
                variant="text"
                onClick={handleChange}
                endIcon={
                  showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
              >
                {showMore ? "Show less" : "Show more"}
              </Button>
            </Divider>
          </Box>
        </>
      )}
    </Container>
  );
};

export default AwardsRecognitions;
