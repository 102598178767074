import React from "react";
import { Box, Typography } from "@mui/material";

const WorkPlayCard = (props) => {
  return (
    <Box
      sx={{
        margin: "16px 10px",
      }}
    >
      <Box
        component="img"
        src={props.img}
        alt={props.title}
        sx={{ width: "100%", maxWidth: "376px" }}
      />
      <Typography
        component="p"
        sx={{
          fontWeight: 700,
          mt: 2,
          fontSize: "1.75rem",
          textTransform: "uppercase",
          fontStyle: "italic",
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

export default WorkPlayCard;
