import React from "react";
import { Box, Link, Typography } from "@mui/material";
import LocationIcon from "../../icons/LocationIcon.svg";
import GlobeIcon from "../../icons/GlobeIcon.svg";

const LocationCard = (props) => {
  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "314px" },
        margin: { xs: "16px 10px", md: "16px 10px" },
      }}
    >
      <Box
        component="img"
        sx={{
          height: 158,
          width: 314,
        }}
        alt="Location image"
        src={props.image}
      />
      <Typography
        variant="h3"
        component="p"
        sx={{
          fontStyle: "italic",
          fontWeight: 700,
          mt: 2,
          textTransform: "uppercase",
          fontSize: "1.75 rem",
          lineHeight: "2rem",
        }}
      >
        {props.name}
      </Typography>
      <Typography variant="body5" sx={{ mt: 3 }} component="p">
        {props.address}
      </Typography>
      {props.directions && (
        <Link
          href={props.directions}
          underline="hover"
          target="_blank"
          sx={{ fontWeight: 500, display: "flex", alignItems: "center", my: 2 }}
        >
          <Typography
            color="primary.blue500"
            variant="body1"
            sx={{ fontWeight: 400 }}
            component="p"
          >
            Get Directions
          </Typography>
          <Box
            component="img"
            src={LocationIcon}
            sx={{ width: "18px", height: "18px", ml: 1 }}
            alt="location icon"
          ></Box>
        </Link>
      )}
      {props.website && (
        <Link
          href={props.website}
          underline="hover"
          target="_blank"
          sx={{ fontWeight: 500, display: "flex", alignItems: "center", my: 2 }}
        >
          <Typography
            color="primary.blue500"
            variant="body1"
            sx={{ fontWeight: 400 }}
            component="p"
          >
            Visit Our Website
          </Typography>
          <Box
            component="img"
            src={GlobeIcon}
            sx={{ width: "18px", height: "18px", ml: 1 }}
            alt="location icon"
          ></Box>
        </Link>
      )}
    </Box>
  );
};

export default LocationCard;
