import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slide from "react-reveal/Slide";

const StatBox = (props) => {
  return (
    <Box>
      <Slide bottom cascade>
        <Typography component="p">
          <Typography
            component="span"
            sx={{
              fontSize: 164,
              fontFamily: "Roboto Condensed",
              display: "flex",
              lineHeight: 1,
            }}
          >
            {props.label}
            {props.emphasis && (
              <Typography
                component="em"
                sx={{
                  fontSize: 74,
                  fontStyle: "normal",
                  fontFamily: "Roboto Condensed",
                }}
              >
                {props.emphasis}
              </Typography>
            )}
          </Typography>
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: { xs: "center", lg: "left" } }}
        >
          {props.footer}
        </Typography>
      </Slide>
    </Box>
  );
};

export default StatBox;
