import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const StaticFeatureCard = (props) => {
  return (
    <Box
      sx={{
        width: "313px",
        height: "378px",
        overflow: "hidden",
        m: 1.25,
        display: "inline-block",
        textAlign: "left",
      }}
      className="static-feature-card"
    >
      <Box
        sx={{
          width: "313px",
          height: "378px",
          backgroundColor: "neutral.grey200",
        }}
        className="static-feature-card__top"
      >
        <Box
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: 700 }}>{props.header}</Typography>
              <Box
                sx={{
                  backgroundColor: "neutral.grey300",
                  height: "2px",
                  width: "28px",
                  marginLeft: 2,
                }}
              ></Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <AddIcon color="secondary" />
            </Grid>
          </Grid>
          <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
            {props.icon && <img src={props.icon} alt={props.footer} />}
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "1.5rem",
                lineHeight: "2rem",
                letterSpacing: "-0.02em",
                height: "64px",
              }}
            >
              {props.footer}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "313px",
          height: "378px",
          backgroundColor: "primary.blue800",
        }}
        className="static-feature-card__bottom"
      >
        <Box
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box sx={{ marginBottom: 3 }}>
            {props.hoverIcon && (
              <img src={props.hoverIcon} alt={props.footer} />
            )}
          </Box>
          <Box color="primary.contrastText" sx={{ whiteSpace: "wrap" }}>
            <Typography
              sx={{
                fontSize: "1.5rem",
                lineHeight: "2rem",
                letterSpacing: "-0.03em",
              }}
            >
              {props.hoverLabel}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                pt: 1,
              }}
            >
              {props.hoverText}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StaticFeatureCard;
