import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import Button from "@mui/material/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  height: 80,
  width: 80,
  backgroundColor: "#ffffff",
  borderRadius: 40,
  opacity: 0.88,
  border: 0,
  "&.MuiButtonBase-root:hover": {
    backgroundColor: "#ffffff",
    border: 0,
    opacity: 1,
  },
  [theme.breakpoints.down("sm")]: {
    height: 64,
    width: 64,
    borderRadius: 32,
  },
}));

const PlayButton = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <StyledButton
        onClick={() => setOpen(true)}
        aria-label="Play video"
        sx={{ ...props.sx }}
      >
        <PlayArrowIcon
          sx={{ width: 40, height: 40, color: "primary.blue700" }}
        ></PlayArrowIcon>
      </StyledButton>
      <ModalVideo
        channel={props.channel}
        autoplay
        isOpen={isOpen}
        videoId={props.videoID}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default PlayButton;
