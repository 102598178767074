import React from "react";
import Box from "@mui/material/Box";
import { Container, Grid, Typography } from "@mui/material";
import SolutionsAccordion from "../components/SolutionsAccordion/SolutionsAccordion";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const FreightSolutions = () => {
  const items = [
    {
      label: "Truckload Solutions",
      body: "RPM provides adaptable, flexible, and reliable truckload solutions throughout North America, including Canada and Mexico. Drop trailer capacity to increase supply chain velocity.",
      img: "/assets/network-design.webp",
    },
    {
      label: "Specialized/Heavy Haul",
      body: "RPM recognizes not all shipments are created equal. We’ve built a dedicated team with industry expertise to support your most demanding specialized shipments.",
      img: "/assets/specialized-haul.webp",
    },
    {
      label: "Expedite",
      body: "Established carrier network consisting of NA’s most trusted National, Regional, and local expedite carriers. RPM can optimize shipments based on equipment needs and services required",
      img: "/assets/expedite.webp",
    },
    {
      label: "Mexico Cross-Border",
      body: "RPM holds Partnerships with major MX providers with facilities in Laredo & Nuevo Laredo. We're able to provide I/B & O/B cross-border services to all points in Mexico",
      img: "/assets/mexico-cross-border.webp",
    },
    {
      label: "Chemical, Bulk & Tanker",
      body: "Hazardous Materials (Including Class 4 and Class 7). EPA Hazardous Waste. Industrial Grade, Kosher Food Grade, and USP Grade Service Cross Border Service into Canada and Mexico",
      img: "/assets/chemical-tanker.webp",
    },
    {
      label: "LTL",
      body: "With a decade of experience providing LTL services, our customers rely on RPM’s myriad of solutions to reduce costs, mitigate claims, and provide white-glove service from quote to invoice.",
      img: "/assets/forklift.webp",
    },
  ];

  const theme = useTheme();
  return (
    <Container disableGutters={useMediaQuery(theme.breakpoints.down("md"))}>
      <Box sx={{ p: 4, my: 5 }}>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Typography variant="body2" component="p">
              RPM Capabilities
            </Typography>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
                my: 4,
              }}
            >
              We're Solving The Industry's Most Pressing Supply Chain Challenges
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              We provide short and long-term solutions that seamlessly adapt to
              your immediate and ever-changing needs. utilizing a solution-based
              approach. Our expriesed team collerabtes with you to define, and
              desin a fullproof transportraion plan.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={2}></Grid>
          <Grid item xs={12} lg={6}>
            <SolutionsAccordion items={items} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FreightSolutions;
