import { Box, Button, Typography } from "@mui/material";
import React from "react";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const TransportOptions = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        maxWidth: "1306px",
        margin: "auto",
        p: { xs: 2, lg: 0 },
        alignItems: { xs: "center", lg: "normal" },
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: "642px",
          backgroundColor: "neutral.grey200",
          mb: 4,
        }}
      >
        <Box
          component="img"
          src="/assets/vehicle-transport.webp"
          alt="Vehicle transport"
          sx={{
            height: "auto",
            width: "100%",
            maxWidth: 642,
            maxHeight: 313,
          }}
        />
        <Box sx={{ py: 5, px: { xs: 3, md: 6 } }}>
          <Typography sx={{ fontSize: "1.25rem" }}>
            Vehicle Transport
          </Typography>
          <Typography
            variant="h2"
            component="p"
            sx={{ textTransform: "none", pt: 4 }}
          >
            OEM, Remarketing, Retail, and POV
          </Typography>
          <Typography sx={{ pt: 3 }}>
            We understand the nuances of finished vehicle logistics, including
            vehicle launches, special vehicle moves, EV moves, and home
            delivery.
          </Typography>
          <Button
            disableElevation
            variant="contained"
            sx={{ mt: 2 }}
            endIcon={<ChevronRightRoundedIcon />}
            href="/shippers/vehicles"
          >
            Vehicle services
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          maxWidth: "642px",
          backgroundColor: "neutral.grey200",
          mb: 4,
        }}
      >
        <Box
          component="img"
          src="/assets/freight-transport.webp"
          alt="Freight transport"
          sx={{
            height: "auto",
            width: "100%",
            maxWidth: 642,
            maxHeight: 313,
          }}
        />
        <Box sx={{ py: 5, px: { xs: 3, md: 6 } }}>
          <Typography sx={{ fontSize: "1.25rem" }}>
            Freight Transport
          </Typography>
          <Typography
            variant="h2"
            component="p"
            sx={{ textTransform: "none", pt: 4 }}
          >
            High & Heavy and Specialized Cargo
          </Typography>
          <Typography sx={{ pt: 3 }}>
            We handle select commodities via specialized modalities, including
            breakbulk container and temperature requirements.
          </Typography>
          <Button
            disableElevation
            variant="contained"
            sx={{ mt: 2 }}
            endIcon={<ChevronRightRoundedIcon />}
            href="/shippers/freight"
          >
            Freight services
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TransportOptions;
