import { Box, Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import ArticleCard from "../components/ArticleCard/ArticleCard";
import Moment from "moment";

const NewsArticle = () => {
  const { slug } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [item, setItem] = useState([]);
  const [relatedItems, setRelatedItem] = useState([]);

  useEffect(() => {
    const URL = `https://admin.rpmmoves.com/api/articles/${slug}`;
    fetch(URL)
      .then((response) => response.json())
      .then(
        (body) => {
          setItem(body.article);
          setRelatedItem(body.relatedArticles);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [slug]);

  if (error) {
    return (
      <>
        <Helmet>
          <title>RPM | News</title>
          <meta name="description" content="Terms & Conditions" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Container maxWidth="md" sx={{ height: "50vh" }}>
          <Typography
            variant="h4"
            component="p"
            id="available-positions"
            sx={{
              fontStyle: "italic",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 9,
              textAlign: "center",
              mb: 4,
              px: 2,
            }}
          >
            News article not found...
          </Typography>
        </Container>
      </>
    );
  } else if (!isLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>RPM | {item.title}</title>
          <meta name="description" content="Terms & Conditions" />
        </Helmet>
        <Container maxWidth="md">
          <Box sx={{ py: 5 }}>
            <Typography
              variant="h2"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
                mb: 3,
                textAlign: "center",
              }}
            >
              {item.title}
            </Typography>
            <Typography
              component="p"
              variant="body5"
              sx={{ pb: 3, textAlign: "center" }}
            >
              {Moment(item.published_date).format("D MMM YYYY")}
            </Typography>
            {item.firstPhoto && (
              <Box sx={{ mb: 3, textAlign: "center" }}>
                <Box
                  component="img"
                  src={
                    "https://cdn.wisefront.com/" + item.firstPhoto.large_path
                  }
                  sx={{ width: "100%", maxWidth: "816px", height: "auto" }}
                  alt="Blog"
                />
              </Box>
            )}
            <div
              className="article-content"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </Box>
          <Divider />
          {item.attribution && (
            <>
              <Box
                sx={{
                  mt: 3,
                  py: 2,
                  display: "flex",
                  alignItems: { xs: "center", sm: "flex-start" },
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                {item.attributionPhoto && (
                  <Box sx={{ mr: 3, mb: 3 }}>
                    <img
                      src={
                        "https://cdn.wisefront.com/" +
                        item.attributionPhoto.large_path
                      }
                      width="144"
                      height="144"
                      alt="Attribution"
                    />
                  </Box>
                )}
                <div
                  className="article-content"
                  dangerouslySetInnerHTML={{ __html: item.attribution }}
                />
              </Box>
              <Divider />
            </>
          )}
        </Container>
        {relatedItems && (
          <>
            <Container>
              <Typography
                variant="h2"
                sx={{
                  fontStyle: "italic",
                  fontWeight: 700,
                  mb: 3,
                  pt: 5,
                  textAlign: "center",
                }}
              >
                Related Articles
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  pb: 3,
                  mt: 5,
                  justifyContent: "space-evenly",
                  alignItems: "flex-start",
                }}
              >
                {relatedItems.map((relatedItem) => {
                  return (
                    <ArticleCard
                      key={relatedItem.id}
                      title={relatedItem.title}
                      publishedDate={relatedItem.published_date}
                      image={relatedItem.firstPhoto}
                      slug={relatedItem.slug}
                      type="news"
                    />
                  );
                })}
              </Box>
            </Container>
          </>
        )}
      </>
    );
  }
};

export default NewsArticle;
