import React from "react";
import { Helmet } from "react-helmet";
import SupportInternal from "../modules/SupportInternal";
import TechnologyFirst from "../modules/TechnologyFirst";
import TechApp from "../modules/TechApp";
import AwardsRecognitions from "../modules/AwardsRecognitions";
import { Box, Typography } from "@mui/material";
import TopHero from "../modules/TopHero";

const heroItems = ["/assets/technology-hero-slide-1.webp"];

const Technology = () => {
  return (
    <>
      <Helmet>
        <title>Technology | Increase Efficiency | RPM</title>
        <meta
          name="description"
          content="Our robust network of 40,0000+ vetted and monitored carriers allows us to quickly fill capacity. We can flex up or down to meet shipper demands."
        />
        <meta
          name="keywords"
          content="vehicle transport, car transportation services, vehicle transportation services, car transportation, american auto transport, auto logistics"
        />
        <meta property="og:title" content="Carriers | Drive With Us | RPM" />
        <meta
          property="og:description"
          content="Our robust network of 40,0000+ vetted and monitored carriers allows us to quickly fill capacity. We can flex up or down to meet shipper demands."
        />
      </Helmet>
      <TopHero
        direction="left"
        heroItems={heroItems}
        tagline="Technology Drives RPM"
        copy="At RPM, our technology drives us forward; It maintains and extends
            our foothold in the market. This allows us to create custom
            logistical solutions, making us the premier partner in both shipping
            and carrier spaces."
      />
      <Box
        sx={{
          mt: 10,
          px: 4,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          color="primary.main"
          sx={{
            textAlign: "center",
            maxWidth: "1130px",
            height: { xs: "auto", sm: "auto" },
            fontSize: { xs: "1.5rem", md: "2rem" },
            lineHeight: "1.2",
          }}
        >
          “Technology allows us to provide superior service to our shippers by
          optimizing shipments to create stronger carrier relationships.
          Although we believe there will always be a need for human interaction,
          we pride ourselves on finding balance and creating a virtuous cycle
          for the shippers, the carriers, and RPM.”
        </Typography>
        <Typography color="primary.main" sx={{ fontWeight: 700, mt: 7 }}>
          Rick Grubb
        </Typography>
        <Typography color="primary.main" variant="body5" sx={{ pt: 1 }}>
          Chief Information Officer
        </Typography>
      </Box>
      <TechnologyFirst />
      <TechApp />
      <AwardsRecognitions />
      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
};

export default Technology;
