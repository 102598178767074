import React from "react";
import { Box, Typography } from "@mui/material";

const RPMmyCard = (props) => {
  return (
    <Box
      sx={{
        margin: "16px 10px",
        width: 200,
        height: 311,
        position: "relative",
        overflow: "hidden",
      }}
      className="rpmmy-card"
    >
      <Box
        component="img"
        src={props.img}
        alt={props.title}
        sx={{ width: "100%" }}
      />
      <Box
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 200,
          height: 76,
          backgroundColor: "primary.blue700",
          py: 2,
          px: 1
        }}
      >
        <Typography
          component="p"
          sx={{
            fontWeight: 700,
            color: "#fff",
            letterSpacing: 0,
            pb: 0.5,
          }}
        >
          {props.name}
        </Typography>
        <Typography variant="body1" sx={{ color: "#fff" }}>
          {props.title}
        </Typography>
      </Box>
      <Box
        sx={{
          py: 2,
          px: 1,
          backgroundColor: "primary.blue700",
          height: 311,
          top: 311,
          left: 0,
          width: 200,
          zIndex: 1,
          position: "absolute",
        }}
        className="rpmmy-card__hover"
      >
        <Typography
          component="p"
          sx={{
            fontWeight: 700,
            color: "#fff",
            letterSpacing: 0,
            pb: 0.5,
          }}
        >
          {props.name}
        </Typography>
        <Typography variant="body1" sx={{ color: "#fff" }}>
          {props.details}
        </Typography>
      </Box>
    </Box>
  );
};

export default RPMmyCard;
