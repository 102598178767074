import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Container, Grid } from "@mui/material";
import SwipeableViews from "react-swipeable-views";

const AutoPlaySwipeableViews = SwipeableViews;

export default function PeopleCarousel(props) {
  const steps = props.items;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Container
      maxWidth="md"
      sx={{ pb: 9, position: "relative" }}
      disableGutters
    >
      <Grid
        container
        sx={{ flexDirection: { xs: "column-reverse", lg: "row" } }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            interval={8000}
          >
            {steps.map((step, index) => (
              <Box
                key={index}
                sx={{
                  height: 424,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <iframe
                  className="video-embed"
                  allowFullScreen
                  frameBorder="0"
                  height="315"
                  width="315"
                  title="0 to 60 video"
                  src={
                    "https://www.youtube.com/embed/" + steps[activeStep].video
                  }
                ></iframe>
              </Box>
            ))}
          </AutoPlaySwipeableViews>
          <Box
            sx={{
              width: "184px",
              position: "absolute",
              right: "50%",
              zIndex: 1,
              bottom: -40,
              transform: "translateX(50%)",
            }}
          >
            <MobileStepper
              variant="dots"
              sx={{ background: "transparent" }}
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                  aria-label="navigate to next"
                  sx={{
                    width: "64px",
                    height: "64px",
                    borderRadius: "32px",
                    backgroundColor: "neutral.grey200",
                    mx: 1.25,
                    mt: 2,
                  }}
                >
                  {theme.direction === "rtl" ? (
                    <ArrowBackIcon fontSize="large" />
                  ) : (
                    <ArrowForwardIcon fontSize="large" />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  aria-label="navigate to previous"
                  sx={{
                    width: "64px",
                    height: "64px",
                    borderRadius: "32px",
                    backgroundColor: "neutral.grey200",
                    mx: 1.25,
                    mt: 2,
                  }}
                >
                  {theme.direction === "rtl" ? (
                    <ArrowForwardIcon fontSize="large" />
                  ) : (
                    <ArrowBackIcon fontSize="large" />
                  )}
                </Button>
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            p: 0,
          }}
        >
          <Box sx={{ maxWidth: 315 }}>
            <Typography
              variant="h2"
              component="p"
              sx={{ fontWeight: 700, fontStyle: "italic" }}
            >
              Check out our 0-60 Spotlights!
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
              Look into the lives of RPMers! Watch our people share their
              experiences in the office!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
