import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Container, Grid } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function Testimonials(props) {
  const steps = props.testimonials;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Container
      sx={{ py: 9, display: "flex", flexGrow: 1, flexDirection: "column" }}
    >
      {props.headline && (
        <Typography
          variant="h2"
          component="p"
          sx={{
            fontStyle: "italic",
            fontWeight: 700,
            textAlign: "center",
            py: 3,
          }}
        >
          {props.headline}
        </Typography>
      )}
      <Grid
        container
        sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: { xs: "125px", md: "250px" },
              position: "relative",
              display: "flex",
              flex: 1,
            }}
          >
            {steps[activeStep].image && (
              <Box
                component="img"
                src={steps[activeStep].image}
                sx={{
                  width: "250px",
                  height: "250px",
                  margin: "auto",
                  mt: { xs: 3, md: 0 },
                  top: 0,
                }}
              />
            )}
          </Box>
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                aria-label="navigate to next"
                sx={{
                  width: "64px",
                  height: "64px",
                  borderRadius: "32px",
                  backgroundColor: "neutral.grey200",
                  mx: 1.25,
                  mt: 2,
                }}
              >
                {theme.direction === "rtl" ? (
                  <ArrowBackIcon fontSize="large" />
                ) : (
                  <ArrowForwardIcon fontSize="large" />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                aria-label="navigate to previous"
                sx={{
                  width: "64px",
                  height: "64px",
                  borderRadius: "32px",
                  backgroundColor: "neutral.grey200",
                  mx: 1.25,
                  mt: 2,
                }}
              >
                {theme.direction === "rtl" ? (
                  <ArrowForwardIcon fontSize="large" />
                ) : (
                  <ArrowBackIcon fontSize="large" />
                )}
              </Button>
            }
          />
        </Grid>
        <Grid item xs={12} md={8} sx={{ display: "flex" }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            animateHeight={true}
            interval={8000}
          >
            {steps.map((step, index) => (
              <Box key={index} sx={{ px: 0 }}>
                <Typography
                  color="primary.main"
                  sx={{
                    maxWidth: "740px",
                    fontSize: { xs: "1.5rem", md: "2rem" },
                    lineHeight: "1.2",
                    height: { xs: "100%", sm: "auto" },
                  }}
                >
                  {steps[activeStep].description}
                </Typography>
                <Typography
                  color="primary.main"
                  sx={{ fontWeight: 700, mt: 3 }}
                >
                  {steps[activeStep].author}
                </Typography>
                <Typography color="primary.main" variant="body5">
                  {steps[activeStep].authorTitle}
                </Typography>
              </Box>
            ))}
          </AutoPlaySwipeableViews>
        </Grid>
      </Grid>
    </Container>
  );
}
