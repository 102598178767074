import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Mexico from "./pages/Mexico";
import Carriers from "./pages/Carriers";
import PageNotFound from "./pages/PageNotFound";
import AppBar from "./modules/AppBar";
import Shippers from "./pages/Shippers";
import SiteFooter from "./modules/SiteFooter";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import GeneralTerms from "./pages/GeneralTerms";
import CarrierAgreement from "./pages/CarrierAgreement";
import AboutRPM from "./pages/AboutRPM";
import Technology from "./pages/Technology";
import Vehicles from "./pages/Vehicles";
import Freight from "./pages/Freight";
import Culture from "./pages/Culture";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import News from "./pages/News";
import NewsArticle from "./pages/NewsArticle";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";

function App() {
  return (
    <Router>
      <AppBar></AppBar>
      <Routes>
        <Route exact path="/" element={<Home />} title="RPM Moves" />
        <Route exact path="/about-us" element={<AboutRPM />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog/:slug" element={<BlogPost />} />
        <Route exact path="/careers" element={<Careers />} />
        <Route exact path="/carrier-agreement" element={<CarrierAgreement />} />
        <Route exact path="/carriers" element={<Carriers />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/culture" element={<Culture />} />
        <Route exact path="/general-terms" element={<GeneralTerms />} />
        <Route exact path="/mx" element={<Mexico />} />
        <Route exact path="/privacy" element={<PrivacyPolicy />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/news/:slug" element={<NewsArticle />} />
        <Route exact path="/shippers" element={<Shippers />} />
        <Route exact path="/shippers/freight" element={<Freight />} />
        <Route exact path="/shippers/vehicles" element={<Vehicles />} />
        <Route exact path="/technology" element={<Technology />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <SiteFooter />
    </Router>
  );
}

export default App;
