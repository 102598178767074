import React from "react";
import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Slide from "react-reveal/Slide";

const ModuleHeadline = (props) => {
  return (
    <>
      <Container id="trusted-companies">
        <Box
          sx={{ display: "flex", justifyContent: "center", overflow: "hidden" }}
        >
          <Slide bottom delay={500}>
            <Typography
              variant="h2"
              sx={{
                my: { xs: 7, md: 9 },
                fontStyle: "italic",
                fontWeight: 700,
                textAlign: "center",
                maxWidth: 868,
                ...props.sx,
              }}
            >
              {props.headline}
            </Typography>
          </Slide>
        </Box>
        {props.subHeadline && (
          <Box
            sx={{
              px: 4,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              color="primary.main"
              variant="body1"
              sx={{
                textAlign: "center",
                maxWidth: "760px",
                mb: 6,
              }}
            >
              {props.subHeadline}
            </Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default ModuleHeadline;
