import React from "react";
import { Box, Link, Typography } from "@mui/material";
import SocialLinkedInBlue from "../../icons/SocialLinkedInBlue.svg";

const TeamCard = (props) => {
  return (
    <Box
      sx={{
        margin: { xs: "8px", md: "16px 10px" },
        width: { xs: 154, md: 313 },
      }}
    >
      {props.img && (
        <Box sx={{ position: "relative" }}>
          <Box
            component="img"
            src={props.img}
            alt={props.name}
            sx={{ width: { xs: 154, md: 313 }, height: { xs: 154, md: 313 } }}
          />
          {props.linkedIn && (
            <Link
              href={props.linkedIn}
              target="_blank"
              underline="none"
              sx={{
                position: "absolute",
                bottom: "12px",
                right: "10px",
                backgroundColor: "#ffffff",
                display: "block",
                width: "24px",
                height: "24px",
                borderRadius: "3px",
                zIndex: 1,
              }}
            >
              <Box
                component="img"
                src={SocialLinkedInBlue}
                sx={{ width: "24px", height: "24px" }}
                alt="LinkedIn profile"
              ></Box>
            </Link>
          )}
        </Box>
      )}
      <Typography variant="body1" sx={{ fontWeight: 700, mt: 2 }}>
        {props.name}
      </Typography>
      <Typography variant="body5" sx={{ mt: 0.5 }}>
        {props.title}
      </Typography>
    </Box>
  );
};

export default TeamCard;
