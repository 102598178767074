import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Helmet } from "react-helmet";
import { useTheme } from "@mui/material/styles";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const PageNotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>404 - Page not found</title>
        <meta
          name="description"
          content="Freight and Vehicle Logistics – FTL, LTL, Intermodal, Expedite, Specialized and Consolidation"
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container maxWidth="xl">
        <Box
          sx={{
            minHeight: "50vh",
            p: 2,
            my: 5,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: 456,
                lineHeight: 1,
                fontWeight: 100,
                fontStyle: "normal",
                [theme.breakpoints.down("lg")]: {
                  fontSize: 256,
                },
                [theme.breakpoints.down("md")]: {
                  fontSize: 156,
                },
              }}
            >
              404
            </Typography>
            <Typography
              variant="h1"
              component="p"
              sx={{ fontStyle: "italic", color: "neutral.grey900" }}
            >
              Looks like you’ve gone off track
            </Typography>
            <Typography sx={{ py: 3, color: "neutral.grey900" }}>
              Sorry, the page you’re looking for does not exist or has been
              removed.
            </Typography>

            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              disableElevation
              endIcon={<ChevronRightRoundedIcon />}
            >
              Find your way back
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default PageNotFound;
