import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const MapNetwork = () => {
  const theme = useTheme();
  return (
    <Container
      disableGutters={useMediaQuery(theme.breakpoints.down("md"))}
      maxWidth="xl"
    >
      <Box
        sx={{
          display: "flex",
          px: 2,
          py: { xs: 6, lg: 8 },
          alignItems: { xs: "center", lg: "flex-start" },
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box
          component="img"
          src="/assets/map-network.webp"
          alt="map"
          sx={{
            width: "100%",
            maxWidth: "900px",
            height: "auto",
            mb: 5,
          }}
        />
        <Box>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
            }}
          >
            Take the Lanes that Fit Your Needs
          </Typography>
          <Typography variant="body1" component="p" sx={{ mt: 3 }}>
            With proprietary tools and technology, RPM optimizes lane data to
            tailor location-specific solutions for the carriers who choose to
            drive with us.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default MapNetwork;
