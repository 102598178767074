import { Box, Container, Typography } from "@mui/material";
import React, { Fragment } from "react";
import LocationCard from "../components/LocationCard/LocationCard";

const OurLocations = () => {
  return (
    <Container maxWidth="xl" sx={{ my: 4 }}>
      <Typography
        variant="h2"
        sx={{
          fontStyle: "italic",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 9,
          textAlign: "center",
          mb: 2,
        }}
      >
        North America
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        <LocationCard
          image="/assets/location-united-states.webp"
          name="Royal Oak, MI"
          address={
            <Fragment>
              301 W. Fourth Ste. 200
              <br />
              Royal Oak, MI 48067, USA
            </Fragment>
          }
          directions="https://www.google.com/maps?daddr=301+W+4th+St+Ste+200,+Royal+Oak,+MI+48067"
        />
        <LocationCard
          image="/assets/location-mexico.webp"
          name="QUÉRETARO"
          address={
            <Fragment>
              Clemencia Borja Taboada 522
              <br />
              Querétaro, Mexico
            </Fragment>
          }
          directions="https://www.google.com/maps?daddr=Calle+R.+M.+Clemencia+Borja+Taboada+522,+Jurica+Acueducto,+76230+Juriquilla,+Qro.,+Mexico"
        />
      </Box>
      <Typography
        variant="h2"
        sx={{
          fontStyle: "italic",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 9,
          textAlign: "center",
          mb: 2,
        }}
      >
        Europe
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        <LocationCard
          image="/assets/location-amsterdam.webp"
          name="Amsterdam"
          address={
            <Fragment>
              Keizersgracht 555 1<br />
              017 DR Amsterdam, The Netherlands
            </Fragment>
          }
          directions="https://www.google.com/maps?daddr=Keizersgracht+555+1+017+DR+Amsterdam,+The+Netherlands"
          website="/eu"
        />
        <LocationCard
          image="/assets/location-poland.webp"
          name="GDANSK"
          address={
            <Fragment>
              Al. Zwycięstwa
              <br />
              13a 80-219 Gdansk, Poland
            </Fragment>
          }
          directions="https://www.google.com/maps?daddr=Al.+Zwycięstwa+13a+80-219+Gdańsk+Poland"
          website="/eu"
        />
        <LocationCard
          image="/assets/location-germany.webp"
          name="Hamburg"
          address={
            <Fragment>
              Karl-Ahrens-Weg
              <br />
              5522393 Hamburg, Germany
            </Fragment>
          }
          directions="https://www.google.com/maps?daddr=Karl-Ahrens-Weg,+22393+Hamburg,+Germany"
          website="/eu"
        />
      </Box>
    </Container>
  );
};

export default OurLocations;
