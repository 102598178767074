import React from "react";
import { Helmet } from "react-helmet";
import HomeHero from "../modules/HomeHero";
import CaseStudies from "../modules/CaseStudies";
import HomeSolutions from "../modules/HomeSolutions";
import ShipCarryTabs from "../modules/ShipCarryTabs";
import HomeFeatureCards from "../modules/HomeFeatureCards";
import AboutUs from "../modules/AboutUs";
import AwardsRecognitions from "../modules/AwardsRecognitions";
import SupportHome from "../modules/SupportHome";
import Testimonials from "../components/Testimonials/Testimonials";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";
import StatsModule from "../components/StatsModule/StatsModule";

const stats = [
  { label: "7.5k+", footer: "Vehicle Carriers" },
  { label: "50k+", footer: "Vehicles Transported Per Month" },
  { label: "14+", footer: "Industries Serviced" },
];

const testimonials = [
  {
    description: `“RPM really understands my transportation needs and are able to be flexible. They have yet to say “no”, regardless of how complex my request.”`,
    author: "Jeremiah D.",
    authorTitle: "Logistics Coordinator",
  },
  {
    description: `“RPM is always fast, professional, and transparent. Their team is always there for me when I need something shipped as soon as possible.”`,
    author: "Marcus V.",
    authorTitle: "VP, Logistics & Special Projects",
  },
  {
    description: `“I’m not a big app guy, but the RPM Drive App is super easy to use. I can scan VINs quickly and less paperwork is always a plus.”`,
    author: "Joe F.",
    authorTitle: "Carrier Owner/Operator",
  },
];

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          RPM | Professional Vehicle Transport | The Driving Force in Logistics™
        </title>
        <meta
          name="keywords"
          content="vehicle transport, car transportation services, vehicle transportation services, car transportation, american auto transport, auto logistics"
        />
        <meta
          name="description"
          content="Looking for professional vehicle and freight transportation services? RPM is your answer. Click here for the best in American auto transport and logistics."
        />
        <meta
          property="og:title"
          content="RPM | Professional Vehicle Transport | The Driving Force in Logistics™"
        />
        <meta
          property="og:description"
          content="Looking for professional vehicle and freight transportation services? RPM is your answer. Click here for the best in American auto transport and logistics."
        />
      </Helmet>
      <HomeHero
        tagline="We cross borders"
        copy="RPM Mexico caters to OEM suppliers and shippers, servicing challenging market segments. Our cross border logistics service offerings include: transloading, border storage, through-trailer service, first/final mile transportation, door-to-door transportation, spot and contracted services."
      />
      <ModuleHeadline headline="We’re Trusted by Thousands of Companies Across the Globe" />
      <CaseStudies />
      <StatsModule
        stats={stats}
        headline="We’re Solving The Industry’s Most Pressing Supply Chain Challenges"
      />
      <HomeSolutions />
      <ShipCarryTabs />
      <HomeFeatureCards />
      <Testimonials testimonials={testimonials} />
      <AboutUs />
      <AwardsRecognitions />
      <SupportHome />
    </>
  );
};

export default Home;
