import React from "react";
import { Helmet } from "react-helmet";
import { Container, Box, Typography, Button } from "@mui/material";
import Testimonials from "../components/Testimonials/Testimonials";
import SupportInternal from "../modules/SupportInternal";
import AwardsRecognitions from "../modules/AwardsRecognitions";
import FreightSpecializedSolutions from "../modules/FreightSpecializedSolutions";
import FreightFeatureCards from "../modules/FreightFeatureCards";
import FreightSolutions from "../modules/FreightSolutions";
import TopHero from "../modules/TopHero";

const testimonials = [
  {
    description: `“We love to get things done fast. That’s why we love working with RPM.”`,
    author: "Loki Williams Bright",
    authorTitle: "Purchasing & Logistics Manager",
  },
  {
    description: `“Shipping with RPM was seamless, everything arrived perfect and on-time. I will definitely use them for future transport needs.”`,
    author: "Landon M.",
    authorTitle: "Supply Chain & Logistics Manager",
  },
  {
    description: `“RPM really understands my transportation needs and are able to be flexible. They have yet to say “no”, regardless of how complex my request.”`,
    author: "Jeremiah D.",
    authorTitle: "Logistics Coordinator",
  },
];

const heroItems = ["/assets/freight-hero-slide-1.webp"];

const Freight = () => {
  return (
    <>
      <Helmet>
        <title>
          RPM Freight | Work With Professional Freight Transportation
          Specialists
        </title>
        <meta
          name="description"
          content="RPM gives their customers refreshing clarity on car shipping cost and car transport services. Click here to find out how much to ship a car near you."
        />
        <meta
          property="og:title"
          content="RPM Freight | Work With Professional Freight Transportation Specialists"
        />
        <meta
          property="og:description"
          content="RPM gives their customers refreshing clarity on car shipping cost and car transport services. Click here to find out how much to ship a car near you."
        />
      </Helmet>
      <TopHero
        direction="left"
        heroItems={heroItems}
        tagline="Non-Asset Based Solutions for Complex Problems"
        taglineVariant="h2"
        copy="RPM’s proprietary technology combined with data analytics support
        today’s increasingly complex supply chains. With fully integrated
        cloud-based platforms our customers digest info faster, generate
        greater visibility, and drive efficiencies within their network."
        ctaText="Let’s Talk"
        ctaLink="/contact"
        ctaTarget="_self"
      />
      <Container maxWidth="xl" disableGutters>
        <Box
          sx={{
            display: "flex",
            padding: "36px 0",
            alignItems: { xs: "center", lg: "top" },
            justifyContent: "space-between",
            flexDirection: { xs: "column-reverse", lg: "row" },
          }}
        >
          <Box sx={{ maxWidth: { xs: "100%", lg: "434px" }, py: 5, px: 2 }}>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
              }}
            >
              Why RPM?
            </Typography>
            <Typography variant="body1" sx={{ py: 3 }}>
              Our people make a difference. We’ve built a culture that empowers
              our employees to think differently. Our culture promotes
              innovation, drives communication, and values commitment. We pride
              ourselves in maintaining the highest service standards and are
              relentless in our pursuit to exceed expectations.
            </Typography>
            <Button href="/about-us" variant="contained" disableElevation>
              Learn More
            </Button>
          </Box>

          <Box
            component="img"
            src="/assets/why-graphic.webp"
            alt="map"
            sx={{
              width: "100%",
              maxWidth: "845px",
              height: "auto",
            }}
          />
        </Box>
      </Container>

      <FreightFeatureCards />

      <FreightSpecializedSolutions />
      <FreightSolutions />
      <Testimonials testimonials={testimonials} />
      <AwardsRecognitions />
      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
};

export default Freight;
