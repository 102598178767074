import React from "react";
import { Box, Container, Link, Typography } from "@mui/material";

const itemData = [
  {
    img: "/assets/wearerpm-1.webp",
    url: "https://www.instagram.com/p/CnMzyMLB1aR/",
  },
  {
    img: "/assets/wearerpm-2.webp",
    url: "https://www.instagram.com/p/CmuLy6lJkqt/",
  },
  {
    img: "/assets/wearerpm-3.webp",
    url: "https://www.instagram.com/p/CmPjZFALN-J/",
  },
  {
    img: "/assets/wearerpm-4.webp",
    url: "https://www.instagram.com/p/Cl9KTLrsjsM/",
  },
  {
    img: "/assets/wearerpm-5.webp",
    url: "https://www.instagram.com/p/CnFkkPHJXQ9/",
  },
  {
    img: "/assets/wearerpm-6.webp",
    url: "https://www.instagram.com/p/Cm1doGAqNoV/",
  },
  {
    img: "/assets/wearerpm-7.webp",
    url: "https://www.instagram.com/p/Cme6Lzzh4-6/",
  },
  {
    img: "/assets/wearerpm-8.webp",
    url: "https://www.instagram.com/p/CmUEQ7jtRoP/",
  },
  {
    img: "/assets/wearerpm-9.webp",
    url: "https://www.instagram.com/p/Cl1sq4vJI25/",
  },
];

const SocialFeed = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontStyle: "italic",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 9,
          textAlign: "center",
          mb: 2,
          textTransform: "none",
        }}
      >
        #WeAreOneRPM
      </Typography>

      <Box
        sx={{
          maxWidth: "800px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          mb: 4,
        }}
      >
        {itemData.map((item) => (
          <Link
            href={item.url}
            sx={{ display: "block" }}
            target="_blank"
            key={item.img}
          >
            <Box
              component="img"
              src={item.img}
              sx={{ width: "202px", height: "202px", m: 1 }}
              alt="social media image"
            />
          </Link>
        ))}
      </Box>
    </Container>
  );
};

export default SocialFeed;
