import React from "react";
import { Helmet } from "react-helmet";
import HomeHero from "../modules/HomeHero";
import CaseStudies from "../modules/CaseStudies";
import HomeSolutions from "../modules/HomeSolutions";
import ShipCarryTabs from "../modules/ShipCarryTabs";
import HomeFeatureCards from "../modules/HomeFeatureCards";
import AboutUs from "../modules/AboutUs";
import AwardsRecognitions from "../modules/AwardsRecognitions";
import SupportHome from "../modules/SupportHome";
import Testimonials from "../components/Testimonials/Testimonials";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";
import StatsModule from "../components/StatsModule/StatsModule";
import OneRpm from "../modules/OneRpm";

const stats = [
  { label: "93%", footer: "9-Year CAGR" },
  { label: "60k+", footer: "Vehicles Transported Per Month" },
  { label: "30", footer: "Countries We Service" },
  { label: "20+", footer: "Languages We Speak" },
];

const testimonials = [
  {
    description: `“RPM really understands my transportation needs and are able to be flexible. They have yet to say “no”, regardless of how complex my request.”`,
    author: "Jeremiah D.",
    authorTitle: "Logistics Coordinator",
  },
  {
    description: `“RPM is always fast, professional, and transparent. Their team is always there for me when I need something shipped as soon as possible.”`,
    author: "Marcus V.",
    authorTitle: "VP, Logistics & Special Projects",
  },
  {
    description: `“I’m not a big app guy, but the RPM Drive App is super easy to use. I can scan VINs quickly and less paperwork is always a plus.”`,
    author: "Joe F.",
    authorTitle: "Carrier Owner/Operator",
  },
];

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          RPM | Professional Vehicle Transport | The Driving Force in Logistics™
        </title>
        <meta
          name="keywords"
          content="vehicle transport, car transportation services, vehicle transportation services, car transportation, american auto transport, auto logistics"
        />
        <meta
          name="description"
          content="Looking for professional vehicle and freight transportation services? RPM is your answer. Click here for the best in American auto transport and logistics."
        />
        <meta
          property="og:title"
          content="RPM | Professional Vehicle Transport | The Driving Force in Logistics™"
        />
        <meta
          property="og:description"
          content="Looking for professional vehicle and freight transportation services? RPM is your answer. Click here for the best in American auto transport and logistics."
        />
      </Helmet>
      <HomeHero
        tagline="We run toward complexity"
        copy="RPM is an international non-asset based logistics and supply chain solutions company focused on servicing the entire spectrum of the finished vehicle logistics landscape and specialized freight transportation for North America and Europe, including Mexico and Canada."
      />
      <ModuleHeadline headline="We’re Trusted by Thousands of Companies Across the Globe" />
      <CaseStudies />
      <OneRpm />
      <StatsModule
        headline="We’re Solving The Industry’s Most Pressing Supply Chain Challenges"
        stats={stats}
      />
      <HomeSolutions />
      <ShipCarryTabs />
      <HomeFeatureCards />
      <Testimonials testimonials={testimonials} />
      <AboutUs />
      <AwardsRecognitions />
      <SupportHome />
    </>
  );
};

export default Home;
