import { Box, Typography } from "@mui/material";
import React from "react";
import FreightSolutionCard from "../components/FreightSolutionCard copy/FreightSolutionCard";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";

const FreightSpecializedSolutions = () => {
  return (
    <>
      <ModuleHeadline
        headline="Unique Solutions for Specialized Cargo in Select Industries"
        sx={{ my: 0, mt: { xs: 7, md: 9 }, mb: 4, maxWidth: 864 }}
      />

      <Typography
        variant="body1"
        sx={{ margin: "auto", maxWidth: 864, textAlign: "center", pb: 3 }}
      >
        RPM provides long-term solutions that seamlessly adapt to your immediate
        and ever-changing needs.
      </Typography>
      <Typography
        variant="h3"
        component="p"
        sx={{
          fontWeight: 700,
          fontStyle: "italic",
          textAlign: { xs: "left", md: "center" },
          textTransform: "uppercase",
          mt: 2,
          pl: { xs: 2, md: 0 },
        }}
      >
        Retail Services
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          margin: "auto",
          justifyContent: "center",
          mb: { xs: 3, md: 6 },
        }}
      >
        <FreightSolutionCard
          headline="Retailers"
          details="RPM has a long history of moving freight from manufacturer to DC and DC to end store"
        />
        <FreightSolutionCard
          headline="Consumer Products"
          details="RPM understands the nuances of moving high-volume freight through the distribution channel of consumer products"
        />
        <FreightSolutionCard
          headline="Food & Beverage"
          details="Expertise in moving temp control and understanding all requirements"
        />
      </Box>
      <Typography
        variant="h3"
        component="p"
        sx={{
          fontWeight: 700,
          fontStyle: "italic",
          textAlign: { xs: "left", md: "center" },
          textTransform: "uppercase",
          mt: 6,
          pl: { xs: 2, md: 0 },
        }}
      >
        Industrial Services
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          margin: "auto",
          justifyContent: "center",
          mb: { xs: 3, md: 6 },
        }}
      >
        <FreightSolutionCard
          headline="Automotive/Heavy duty truck"
          details="RPM supports inbound OEM components as well as after-market parts"
        />
        <FreightSolutionCard
          headline="Major Manufacturing"
          details="From injection moldings to foam to steel. We support North America’s most recognized manufacturers"
        />
        <FreightSolutionCard
          headline="Robotics & Automation"
          details="Moving a small robotic arm or an entire conveyor system RPM offers a seamless solution leaning on our industry expertise to support the entire movement from end-to-end"
        />
        <FreightSolutionCard
          headline="Energy, Oil & Gas"
          details="RPM’s specialized transportation expertise can assist with any project or fieldwork our customers dig up"
        />
      </Box>
    </>
  );
};

export default FreightSpecializedSolutions;
