import React from "react";
import { Helmet } from "react-helmet";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";
import ShippersCaseStudies from "../modules/ShippersCaseStudies";
import TransportationNetwork from "../modules/TransportationNetwork";
import Testimonials from "../components/Testimonials/Testimonials";
import TransportOptions from "../modules/TransportOptions";
import SupportInternal from "../modules/SupportInternal";
import ShippersFeatureCards from "../modules/ShippersFeatureCards";
import TopHero from "../modules/TopHero";

const testimonials = [
  {
    description: `“RPM is always fast, professional, and transparent. Their team is always there for me when I need something shipped as soon as possible.”`,
    author: "Marcus V.",
    authorTitle: "VP, Logistics & Special Projects",
  },
  {
    description: `“Shipping with RPM was seamless, everything arrived perfect and on-time. I will definitely use them for future transport needs.”`,
    author: "Landon M.",
    authorTitle: "Supply Chain & Logistics Manager",
  },
  {
    description: `“RPM really understands my transportation needs and are able to be flexible. They have yet to say “no”, regardless of how complex my request.”`,
    author: "Jeremiah D.",
    authorTitle: "Logistics Coordinator",
  },
];

const heroItems = ["/assets/shippers-hero-slide-1.webp"];

const Shippers = () => {
  return (
    <>
      <Helmet>
        <title>
          RPM Shippers | Work With Professional Auto Transportation Specialists
        </title>
        <meta
          name="description"
          content="RPM gives their customers refreshing clarity on car shipping cost and car transport services. Click here to find out how much to ship a car near you."
        />
        <meta
          property="og:title"
          content="RPM Shippers | Work With Professional Auto Transportation Specialists"
        />
        <meta
          property="og:description"
          content="RPM gives their customers refreshing clarity on car shipping cost and car transport services. Click here to find out how much to ship a car near you."
        />
      </Helmet>
      <TopHero
        heroItems={heroItems}
        tagline="Complexity is our specialty"
        copy="RPM is a disruptive and differentiated, end-to-end transportation
        logistics provider focused on servicing the entire spectrum of the
        finished vehicle logistics landscape and specialized freight
        transportation for North America and Europe, including Mexico and
        Canada. We leverage a carrier network that uses specialized equipment
        for both spot and contract needs."
        ctaText="Let’s Talk"
        ctaLink="/contact"
        ctaTarget="_self"
      />
      <ModuleHeadline
        headline="We’re Industry Disruptors Who Provide Unconventional Solutions"
        sx={{ marginBottom: "0 !important" }}
      />
      <ShippersFeatureCards />
      <ShippersCaseStudies />
      <TransportationNetwork />
      <TransportOptions />
      <Testimonials testimonials={testimonials} />
      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
};

export default Shippers;
