import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

const OneRpm = () => {
  const theme = useTheme();
  const isMediumSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container maxWidth="xl" disableGutters={isMediumSize ? true : false}>
      <Box
        sx={{
          display: "flex",
          padding: { xs: "56px 0", lg: "72px 0" },
          alignItems: { xs: "center", lg: "top" },
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box
          component="img"
          src="/assets/one-rpm.webp"
          alt="map"
          sx={{
            width: "100%",
            maxWidth: "957px",
            height: "auto",
          }}
        />
        <Box sx={{ maxWidth: "424px", py: 5, px: 2 }}>
          <Typography variant="body2" component="p">
            OneRPM
          </Typography>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
              mt: 4,
              textTransform: "none",
            }}
          >
            WE OPERATE
            <br />
            AS “OneRPM”
          </Typography>
          <Typography variant="body1" component="p" sx={{ my: 3 }}>
            OneRPM™ is our global harmonization solution that aligns standard
            quality processes, operational efficacy, communication, and
            strategic growth across our entire organization.
          </Typography>
          <Button
            href="/eu"
            target="_blank"
            variant="contained"
            disableElevation
          >
            Visit RPM EU
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default OneRpm;
