import * as React from "react";
import { styled } from "@mui/material/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Grid, Link } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

export default function SolutionsAccordion(props) {
  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {props.items.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{ border: "none" }}
        >
          <AccordionSummary
            expandIcon={
              expanded === `panel${index}` ? (
                <RemoveIcon
                  fontSize="large"
                  sx={{ color: "primary.blue800" }}
                />
              ) : (
                <AddIcon fontSize="large" sx={{ color: "primary.blue800" }} />
              )
            }
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
            sx={{
              background: "white",
              borderTop: "1px solid #C4C4C4",
              p: 0,
            }}
          >
            <Typography
              variant="h4"
              component="p"
              sx={{
                fontStyle: "italic",
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            >
              {item.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ border: "none", px: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography>{item.body}</Typography>
                {item.linkText && (
                  <Link href={item.link} underline="hover">
                    <Typography
                      sx={{
                        color: "#173162",
                        display: "inline-flex",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      {item.linkText}
                      <ChevronRightRoundedIcon
                        color="primary.main"
                        sx={{ fontSize: "1.25rem", marginLeft: "4px" }}
                      ></ChevronRightRoundedIcon>
                    </Typography>
                  </Link>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={item.img}
                  alt={item.label}
                  sx={{
                    width: 201,
                    height: 101,
                    display: { xs: "none", md: "block" },
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
