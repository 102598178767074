import { Box, Container, Typography } from "@mui/material";
import React from "react";
import TeamCard from "../components/TeamCard/TeamCard";

const cards = [
  {
    name: "Barry Spilman",
    title: "Founder & Chief Executive Officer",
    img: "/assets/team-barry-spilman.png",
    linkedIn: "https://www.linkedin.com/in/barryspilman/",
  },
  {
    name: "Brandon Slone",
    title: "Chief Human Resources Officer, North America & Europe",
    img: "/assets/team-brandon-slone.png",
    linkedIn: "https://www.linkedin.com/in/brandon-slone-shrm-cp-672031134/",
  },
  {
    name: "John Perkovich",
    title: "Chief Operations Officer, North America & Europe",
    img: "/assets/team-john-perkovich.png",
    linkedIn: "https://www.linkedin.com/in/john-perkovich-5ab4368/",
  },
  {
    name: "Rick Grubb II",
    title: "Chief Information Officer, North America & Europe",
    img: "/assets/team-rick-grubb.png",
    linkedIn: "https://www.linkedin.com/in/rick-grubb-b518021/",
  },
  {
    name: "Bill Kerrigan",
    title: "Chief Commercial Officer, North America & Europe",
    img: "/assets/team-bill-kerrigan.png",
    linkedIn: "https://www.linkedin.com/in/william-h-kerrigan-7a629174/",
  },
  {
    name: "Bradley Zasa",
    title: "Chief Financial Officer, North America & Europe",
    img: "/assets/team-brandley-zasa.png",
    linkedIn: "https://www.linkedin.com/in/bradleyzasa/",
  },
  {
    name: "Jaroslaw Maszczak",
    title: "Senior Vice President of Brokerage at RPM Europe",
    img: "/assets/team-jaroslaw-maszczak.png",
    linkedIn: "https://www.linkedin.com/in/jaroslaw-maszczak-45648625/",
  },
  {
    name: "Marco Siemssen",
    title: "Vice President of Sales & Solutions at RPM Europe",
    img: "/assets/team-marco-siemssen.png",
    linkedIn: "https://www.linkedin.com/in/marco-siemssen-114ab4152/",
  },
  {
    name: "Julian Haines",
    title: "Vice President of Business Strategy at RPM Europe",
    img: "/assets/team-julian-hanes.png",
    linkedIn: "https://www.linkedin.com/in/julian-haines-b344a446/",
  },
  {
    name: "Sean Baltazar",
    title: "Senior Vice President of Sales",
    img: "/assets/team-sean-baltazar.png",
    linkedIn: "https://www.linkedin.com/in/sean-baltazar-52604a47/",
  },
  {
    name: "Drew Sherman",
    title: "Vice President of Marketing",
    img: "/assets/team-drew-sherman.png",
    linkedIn: "https://www.linkedin.com/in/drewsherman-cd/",
  },
];

const RPMTeam = () => {
  return (
    <Container sx={{ my: 4 }}>
      <Typography
        variant="h2"
        sx={{
          fontStyle: "italic",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 9,
          textAlign: "center",
          mb: 2,
        }}
      >
        Leadership Team at RPM
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        {cards.map((card, i) => (
          <TeamCard
            key={i}
            name={card.name}
            title={card.title}
            img={card.img}
            linkedIn={card.linkedIn}
          />
        ))}
      </Box>
    </Container>
  );
};

export default RPMTeam;
