import React from "react";
import { Helmet } from "react-helmet";
import CarriersFeatureCards from "../modules/CarriersFeatureCards";
import MapNetwork from "../modules/MapNetwork";
import Testimonials from "../components/Testimonials/Testimonials";
import SupportInternal from "../modules/SupportInternal";
import Hub from "../modules/Hub";
import DriveApp from "../modules/DriveApp";
import TopHero from "../modules/TopHero";

const testimonials = [
  {
    description: `“Picking up loads with RPM is easy. There is always a rep to talk to and they do a great job of keeping my truck full. That’s what matters most to me.”`,
    author: "Veronica S.",
    authorTitle: "Carrier Owner/Operator",
  },
  {
    description: `“I’m not a big app guy, but the RPM Drive App is super easy to use. I can scan VINs quickly and less paperwork is always a plus.”`,
    author: "Joe F.",
    authorTitle: "Carrier Owner/Operator",
  },
  {
    description: `“I’ve been working with RPM for several years. I always get 24/7 support from a real person. RPM is the easiest 3PL to work with by far.”`,
    author: "Laith H.",
    authorTitle: "Carrier Owner/Operator",
  },
];

const heroItems = ["/assets/carriers-hero-slide-1.webp"];

const Carriers = () => {
  return (
    <>
      <Helmet>
        <title>Carriers | Drive With Us | RPM</title>
        <meta
          name="description"
          content="Our robust network of 40,0000+ vetted and monitored carriers allows us to quickly fill capacity. We can flex up or down to meet shipper demands."
        />
        <meta
          name="keywords"
          content="vehicle transport, car transportation services, vehicle transportation services, car transportation, american auto transport, auto logistics"
        />
        <meta property="og:title" content="Carriers | Drive With Us | RPM" />
        <meta
          property="og:description"
          content="Our robust network of 40,0000+ vetted and monitored carriers allows us to quickly fill capacity. We can flex up or down to meet shipper demands."
        />
      </Helmet>
      <TopHero
        direction="left"
        heroItems={heroItems}
        tagline="Haul with us"
        copy="At RPM, we treat carriers as customers. We aim to reduce empty miles
        while keeping your truck full. We work with all equipment types and
        are always looking to expand the network."
        ctaText="Join Our Network"
        ctaLink={process.env.REACT_APP_HIGHWAY_LINK}
        ctaTarget="_blank"
      />
      <CarriersFeatureCards />
      <MapNetwork />
      <Testimonials
        headline="Hear From Carriers in the Network"
        testimonials={testimonials}
      />
      <Hub />
      <DriveApp />
      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
};

export default Carriers;
