import React from "react";
import { Box, Container } from "@mui/material";
import FeatureCard from "../components/FeatureCard/FeatureCard";
import Carousel from "react-material-ui-carousel";
import Truck from "../icons/Truck.svg";
import TruckHover from "../icons/TruckHover.svg";
import PersonStar from "../icons/PersonStar.svg";
import PersonStarHover from "../icons/PersonStarHover.svg";
import QualitySupport from "../icons/QualitySupport.svg";
import QualitySupportHover from "../icons/QualitySupportHover.svg";
import PersonCheckmark from "../icons/PersonCheckmark.svg";
import PersonCheckmarkHover from "../icons/PersonCheckmarkHover.svg";

const cards = [
  {
    header: "01",
    footer: "Solutions",
    icon: PersonStar,
    hoverIcon: PersonStarHover,
    hoverLabel: "Solutions",
    hoverText:
      "We offer a full suite of services from the standard dry van to specialized open deck equipment. We rely on our team's experience to tailor the right solution to fit your needs.",
  },
  {
    header: "02",
    footer: "Capacity",
    icon: Truck,
    hoverIcon: TruckHover,
    hoverLabel: "Capacity",
    hoverText:
      "Over the past decade, we have built a robust carrier network that includes all equipment types allowing us to provide solutions from your basic dry van to your complex piloted heavy haul and everything in between.",
  },
  {
    header: "03",
    footer: "Quality Support",
    icon: QualitySupport,
    hoverIcon: QualitySupportHover,
    hoverLabel: "Quality Support",
    hoverText:
      "Freight moves 24/7 and so does RPM. We are fully staffed with team members that proactively identify potential issues, and engage the appropriate resources to resolve the matter.",
  },
  {
    header: "04",
    footer: "Single Point of Contact",
    icon: PersonCheckmark,
    hoverIcon: PersonCheckmarkHover,
    hoverLabel: "Single Point of Contact",
    hoverText:
      "RPM provides a single point of contact with 24hr team support throughout every stage of the shipment process.",
  },
];

const FreightFeatureCards = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        my: 4,
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {cards.map((card, i) => (
          <FeatureCard
            key={i}
            header={card.header}
            footer={card.footer}
            icon={card.icon}
            hoverIcon={card.hoverIcon}
            hoverLabel={card.hoverLabel}
            hoverText={card.hoverText}
          />
        ))}
      </Box>
      <Carousel
        interval={5000}
        swipe={false}
        navButtonsAlwaysVisible
        sx={{ textAlign: "center", display: { xs: "block", sm: "none" } }}
        navButtonsWrapperProps={{
          style: {
            display: "flex",
            alignItems: "center",
          },
        }}
        navButtonsProps={{
          style: {
            width: "40px",
            height: "40px",
            color: "#111827",
            backgroundColor: "#F6F6F6",
          },
        }}
        indicatorContainerProps={{
          style: {
            margin: "10px 0",
          },
        }}
        indicatorIconButtonProps={{
          style: {
            width: "16px",
            height: "16px",
            color: "#C4C4C4",
            backgroundColor: "#ffffff",
            margin: "0 12px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            width: "16px",
            height: "16px",
            color: "#173162",
            backgroundColor: "#173162",
          },
        }}
      >
        {cards.map((card, i) => (
          <FeatureCard
            key={i}
            header={card.header}
            footer={card.footer}
            icon={card.icon}
            hoverIcon={card.hoverIcon}
            hoverLabel={card.hoverLabel}
            hoverText={card.hoverText}
          />
        ))}
      </Carousel>
    </Container>
  );
};

export default FreightFeatureCards;
