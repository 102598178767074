import React from "react";
import { Box, Container } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import FeatureCard from "../components/FeatureCard/FeatureCard";
import Payment from "../icons/Payment.svg";
import PaymentHover from "../icons/PaymentHover.svg";
import TruckGauge from "../icons/TruckGauge.svg";
import TruckGaugeHover from "../icons/TruckGaugeHover.svg";
import QualitySupport from "../icons/QualitySupport.svg";
import QualitySupportHover from "../icons/QualitySupportHover.svg";
import PersonCheckmark from "../icons/PersonCheckmark.svg";
import PersonCheckmarkHover from "../icons/PersonCheckmarkHover.svg";

const cards = [
  {
    header: "01",
    footer: "Single Point of Contact",
    icon: PersonCheckmark,
    hoverIcon: PersonCheckmarkHover,
    hoverLabel: "Single Point of Contact",
    hoverText:
      "Allows for a one-to-one relationship with an assigned carrier sales rep who is focused on growing volume.",
  },
  {
    header: "02",
    footer: "Payment in 2 Business Days",
    icon: Payment,
    hoverIcon: PaymentHover,
    hoverLabel: "Payment in 2 Business Days",
    hoverText:
      "Drivers who choose to carry with RPM can sign up for Quick Pay to expedite payment.",
  },
  {
    header: "03",
    footer: "Reduced Empty Miles",
    icon: TruckGauge,
    hoverIcon: TruckGaugeHover,
    hoverLabel: "Reduced Empty Miles",
    hoverText:
      "We work with more than 600+ shippers, allowing us to fill gaps within the carrier network.",
  },
  {
    header: "04",
    footer: "Quality Support",
    icon: QualitySupport,
    hoverIcon: QualitySupportHover,
    hoverLabel: "Quality Support",
    hoverText:
      "When you have a problem, we are here to support you and help solve it!",
  },
];

const CarriersFeatureCards = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        my: 4,
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {cards.map((card, i) => (
          <FeatureCard
            key={i}
            header={card.header}
            footer={card.footer}
            icon={card.icon}
            hoverIcon={card.hoverIcon}
            hoverLabel={card.hoverLabel}
            hoverText={card.hoverText}
          />
        ))}
      </Box>
      <Carousel
        interval={5000}
        swipe={false}
        navButtonsAlwaysVisible
        sx={{ textAlign: "center", display: { xs: "block", sm: "none" } }}
        navButtonsWrapperProps={{
          style: {
            display: "flex",
            alignItems: "center",
          },
        }}
        navButtonsProps={{
          style: {
            width: "40px",
            height: "40px",
            color: "#111827",
            backgroundColor: "#F6F6F6",
          },
        }}
        indicatorContainerProps={{
          style: {
            margin: "10px 0",
          },
        }}
        indicatorIconButtonProps={{
          style: {
            width: "16px",
            height: "16px",
            color: "#C4C4C4",
            backgroundColor: "#ffffff",
            margin: "0 12px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            width: "16px",
            height: "16px",
            color: "#173162",
            backgroundColor: "#173162",
          },
        }}
      >
        {cards.map((card, i) => (
          <FeatureCard
            key={i}
            header={card.header}
            footer={card.footer}
            icon={card.icon}
            hoverIcon={card.hoverIcon}
            hoverLabel={card.hoverLabel}
            hoverText={card.hoverText}
          />
        ))}
      </Carousel>
    </Container>
  );
};

export default CarriersFeatureCards;
