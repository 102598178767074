import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import BulletList from "../components/BulletList/BulletList";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container disableGutters>
          <Box sx={{ py: 2 }}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TechApp = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Drive and HUB apps"
          centered
        >
          <Tab label="RPM Drive App" {...a11yProps(0)} />
          <Tab label="RPM Hub" {...a11yProps(1)} />
          <Tab label="RPM Moves" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box
          sx={{
            display: "flex",
            padding: "36px 0",
            alignItems: { xs: "center", lg: "top" },
            justifyContent: "space-between",
            flexDirection: { xs: "column-reverse", lg: "row" },
          }}
        >
          <Box sx={{ maxWidth: "434px", py: 5, px: 2 }}>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
              }}
            >
              The RPM Drive App increases efficiencies for shippers and
              carriers.
            </Typography>
            <BulletList
              type="number"
              items={[
                "Allows carriers to easily and safely update us on any accidents or other  changes in status.",
                "Makes uploading documentation a breeze with a built-in scanner.",
                "Easy communication with RPM support staff.",
              ]}
            />
            <Link
              href="https://play.google.com/store/apps/details?id=com.loadrpm.Ike"
              target="_blank"
            >
              <Box
                component="img"
                src="/assets/app-google.webp"
                alt="Google play app download"
                sx={{ width: 146, height: 44, m: { xs: 1, sm: 0.5 } }}
              />
            </Link>
            <Link
              href="https://apps.apple.com/us/app/rpm-drive/id1476279456"
              target="_blank"
            >
              <Box
                component="img"
                src="/assets/app-ios.webp"
                alt="IOS app download"
                sx={{ width: 146, height: 44, m: { xs: 1, sm: 0.5 } }}
              />
            </Link>
          </Box>

          <Box
            component="img"
            src="/assets/rpm-phone-app.webp"
            alt="map"
            sx={{
              width: "100%",
              maxWidth: "651px",
              height: "auto",
            }}
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            display: "flex",
            padding: "36px 0",
            alignItems: { xs: "center", lg: "top" },
            justifyContent: "space-between",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            component="img"
            src="/assets/rpm-hub-app.webp"
            alt="map"
            sx={{
              width: "100%",
              maxWidth: "710px",
              height: "auto",
            }}
          />
          <Box sx={{ maxWidth: "434px", py: 5, px: 2 }}>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
              }}
            >
              Seamless Load Management with the RPM Hub
            </Typography>
            <Typography variant="body1" sx={{ py: 3 }}>
              The RPM Hub is a marketplace for vehicle carriers to pick and
              choose shipments, based on their preferences and needs. It reduces
              deadhead/empty miles by allowing carriers full access to available
              shipments digitally.
            </Typography>
            <Button
              href={process.env.REACT_APP_HIGHWAY_LINK}
              variant="contained"
              disableElevation
              target="_blank"
            >
              Haul with Us
            </Button>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box
          sx={{
            display: "flex",
            padding: "36px 0",
            alignItems: { xs: "center", lg: "top" },
            justifyContent: "space-between",
            flexDirection: { xs: "column-reverse", lg: "row" },
          }}
        >
          <Box sx={{ maxWidth: "434px", py: 5, px: 2 }}>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
              }}
            >
              RPM Moves You
            </Typography>
            <Typography variant="body1" sx={{ py: 3 }}>
              RPM Moves is RPM's business-to-consumer (B2C) auto transport
              service, specializing in privately owned vehicles. This
              consumer-facing service leverages our expansive network of
              carriers to handle consumer open, enclosed, and expedited moves
              throughout the continental U.S. RPM Moves offers a quick and easy
              quoting system that makes shipping your personal vehicle a breeze.
            </Typography>
            <Button
              href="/individual"
              variant="contained"
              disableElevation
              target="_blank"
            >
              More about RPM Moves
            </Button>
          </Box>

          <Box
            component="img"
            src="/assets/RPMMoves_Logo.png"
            alt="map"
            sx={{
              width: "100%",
              maxWidth: "720px",
              height: "auto",
            }}
          />
        </Box>
      </TabPanel>
    </Box>
  );
};

export default TechApp;
