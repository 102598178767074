import React from "react";
import { Box, Container, Typography } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { styled } from "@mui/material/styles";
import FormDialog from "../components/FormDialog";

const SupportBox = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "40px 0",
  margin: "110px 0",
  flexDirection: "row",
  "&:before": {
    content: '""',
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#0b1232",
    borderLeft: "20px solid #0076b6",
    transform: "matrix(0.89, 0, 0.6, 1, 0, 0)",
    zIndex: 0,
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    padding: "72px 20px",
    backgroundColor: "#F6F6F6",
    margin: "0",
    "&:before": {
      display: "none",
    },
  },
}));

const SupportHome = () => {
  return (
    <Container maxWidth="xl" disableGutters>
      <SupportBox>
        <Typography
          variant="h2"
          component="p"
          sx={{
            color: { xs: "primary.blue800", lg: "white" },
            fontWeight: 700,
            position: "relative",
            fontStyle: "italic",
            zIndex: 1,
          }}
        >
          We’re here to help
        </Typography>
        <Typography
          sx={{
            maxWidth: "390px",
            color: { xs: "neutral.grey400", lg: "white" },
            zIndex: 1,
            mx: { xs: 0, lg: 5 },
            my: { xs: 2, lg: 0 },
          }}
        >
          No matter the logistics problem, we are confident our experienced team
          will find the solution. We are here to support you 24/7/365.
        </Typography>
        <FormDialog
          label="Let’s Talk"
          sx={{ my: 2, display: { xs: "flex", lg: "none" } }}
          variant="contained"
          endIcon={<ChevronRightRoundedIcon />}
        />
        <FormDialog
          label="Let’s Talk"
          sx={{ my: 2, display: { xs: "none", lg: "flex" } }}
          variant="outlined"
          disableElevation
          endIcon={<ChevronRightRoundedIcon />}
        />
      </SupportBox>
    </Container>
  );
};

export default SupportHome;
