import React from "react";
import { Box, Link } from "@mui/material";

const SocialLink = (props) => {
  return (
    <Link
      href={props.url}
      target="_blank"
      underline="none"
    >
      <Box sx={{ mr: 2 }}>
        <img src={props.img} alt={props.label} width="24" height="28" />
      </Box>
    </Link>
  );
};

export default SocialLink;
