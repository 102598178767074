import React from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Typography, Container } from "@mui/material";
import PlayButton from "../components/PlayButton/PlayButton";
import { styled } from "@mui/material/styles";
import { useMediaQuery, useTheme } from "@mui/material";

const HeroBox = styled(Box)(({ theme }) => ({
  height: 575,
  position: "relative",
  marginBottom: "56px",
}));

const HeroBoxTop = styled(Box)(({ theme }) => ({
  margin: "72px 0 0 0",
  maxWidth: "430px",
  height: "575px",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
    margin: 0,
    padding: "56px 24px",
    height: "auto",
  },
}));

const IndustryNewbie = () => {
  const handleClick = () => {
    document
      .getElementById("available-positions")
      .scrollIntoView({ behavior: "smooth" });
  };
  const theme = useTheme();
  const isMediumSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container maxWidth="lg" disableGutters={isMediumSize ? true : false}>
      <HeroBox>
        <Grid
          container
          sx={{ flexDirection: { xs: "column", lg: "row" } }}
        >
          <Grid item xs={12} lg={4}>
            <HeroBoxTop>
              <Typography
                variant="h2"
                component="p"
                sx={{
                  fontStyle: "italic",
                  fontWeight: 700,
                  mt: 4,
                  pt: { xs: 0, lg: 17 },
                }}
              >
                Industry Newbie?
              </Typography>
              <Typography variant="body1" component="p" sx={{ mt: 3 }}>
                The Logistics industry is full of diverse projects and
                experiences. A range of benefits are offered through RPM’s
                Training Program for new RPMers, including the chance to get
                acclimated to our culture and proprietary tools before entering
                your role. Watch Training Directors Meghan Caltrider and Jack
                Cunningham share insight on the program’s origin, value, and
                future.
              </Typography>
              <Button variant="contained" onClick={handleClick} sx={{ mt: 3 }}>
                Setting You Up For Success
              </Button>
            </HeroBoxTop>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: `url(${"/assets/industry-newbie-2.webp"})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
          >
            <Box
              sx={{ height: "240px", display: "flex", alignItems: "center" }}
            >
              <PlayButton videoID="_yL3ZZD9USk" channel="youtube" />
            </Box>
          </Grid>
        </Grid>
      </HeroBox>
    </Container>
  );
};

export default IndustryNewbie;
