import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const BehaviourHeadline = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto Condensed",
  fontSize: "1.75rem",
  lineHeight: "2rem",
  textTransform: "uppercase",
  fontStyle: "italic",
  fontWeight: 700,
  margin: "16px 0",
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    textAlign: "left",
  },
}));

const BehaviorCard = (props) => {
  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "202px" },
        margin: { xs: "20px 24px", md: "20px 40px" },
      }}
    >
      <BehaviourHeadline>{props.headline}</BehaviourHeadline>
      <Typography
        variant="body1"
        sx={{ textAlign: { xs: "center", sm: "left" } }}
      >
        {props.details}
      </Typography>
    </Box>
  );
};

export default BehaviorCard;
