import React from "react";
import ReactDOM from "react-dom/client";
import RPM from "./themes/RPM";
import { ThemeProvider } from "@mui/material";
import App from "./App";

import "./App.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={RPM}>
    <App />
  </ThemeProvider>
);
