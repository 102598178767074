import React from "react";
import { Helmet } from "react-helmet";
import AboutHero from "../modules/AboutHero";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";
import BehaviorCard from "../components/BehaviorCard/BehaviorCard";
import OurHistory from "../modules/OurHistory";
import Testimonials from "../components/Testimonials/Testimonials";
import CareerCultureTabs from "../modules/CareerCultureTabs";
import AwardsRecognitions from "../modules/AwardsRecognitions";
import RPMTeam from "../modules/RPMTeam";
import OurLocations from "../modules/OurLocations";
import SupportInternal from "../modules/SupportInternal";

const testimonials = [
  {
    description: `“Working for a company where people thrive because we know the company supports us; opportunity becomes endless for what RPM provides customers and carriers alike. Building relationships is the foundation of what we do here.”`,
    author: "Ryan Gabbara,",
    authorTitle: "Digital Marketing Coordinator",
    image: "/assets/ryan-gabbara.webp",
  },
  {
    description: `“RPM takes care of its people. I have had the autonomy to select, train, and assemble a high-quality team, which benefits our Customers and promotes continued growth. The entrepreneurial and progressive attitude of RPM's top management has challenged my strengths and enabled me to be the best that I can be.”`,
    author: "Shannon Lapointe,",
    authorTitle: "Director of Remarketing",
    image: "/assets/shannon-lapointe.webp",
  },
  {
    description: `“I love RPM because we have a culture here that truly allows us to be ourselves. I am not just selling freight, I am actually selling myself to create relationships. It's the company culture that allows me to succeed.”`,
    author: "Iris Mesic,",
    authorTitle: "Carrier Sales Rep",
    image: "/assets/iris-mesic.webp",
  },
  {
    description: `“Everyone at RPM is fully dedicated to their job. The company has great leaders who inspire you to work harder to become a better individual, a better worker, and a better you.”`,
    author: "Meghan Feldman,",
    authorTitle: "Remarketing Account Manager",
    image: "/assets/meghan-feldman.webp",
  },
];

const AboutRPM = () => {
  return (
    <>
      <Helmet>
        <title>About RPM | The Driving Force in Logistics™</title>
        <meta
          name="keywords"
          content="vehicle transport, car transportation services, vehicle transportation services, car transportation, american auto transport, auto logistics"
        />
        <meta
          name="description"
          content="Looking for professional vehicle and freight transportation services? RPM is your answer. Click here for the best in American auto transport and logistics."
        />
        <meta
          property="og:title"
          content="RPM | Professional Vehicle Transport | The Driving Force in Logistics™"
        />
        <meta
          property="og:description"
          content="Looking for professional vehicle and freight transportation services? RPM is your answer. Click here for the best in American auto transport and logistics."
        />
      </Helmet>
      <AboutHero />
      <ModuleHeadline
        headline="OUR OneRPM PHILOSOPHY"
        sx={{ my: 0, mt: { xs: 7, md: 9 }, mb: 4, textTransform: "none" }}
      />
      <Box
        sx={{
          px: 4,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          color="primary.main"
          sx={{
            textAlign: "center",
            maxWidth: "1130px",
            height: { xs: "auto", sm: "auto" },
            fontSize: { xs: "1.5rem", md: "2rem" },
            lineHeight: "1.2",
          }}
        >
          “We are an international company, so it’s imperative that we operate
          as one. No more siloed departments, no more siloed objectives, no more
          siloed processes. We must collectively share the same mission, vision,
          and strategy to achieve our goals, together.”
        </Typography>
        <Typography color="primary.main" sx={{ fontWeight: 700, mt: 7 }}>
          Barry Spilman
        </Typography>
        <Typography color="primary.main" variant="body5" sx={{ pt: 1 }}>
          Founder, and Chief Executive Officer of RPM
        </Typography>
      </Box>

      <ModuleHeadline
        headline="Our 5 core behaviors"
        sx={{ my: 0, mt: { xs: 7, md: 9 }, mb: 4 }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pb: 9,
          flexWrap: "wrap",
        }}
      >
        <BehaviorCard
          headline="Honesty"
          details="We need to be honest with ourselves and maintain self-awareness in all situations. Honesty promotes a healthier team experience and allows better productivity."
        />
        <BehaviorCard
          headline="Trust"
          details="Leadership begins by giving trust and not asking people to earn it. By trusting our employees we motivate them to deliver and trust others."
        />
        <BehaviorCard
          headline="Transparency"
          details="We must be upfront with our teams, keeping them in the loop sharing the good and the bad. It promotes ownership and allows for collaborative problem-solving."
        />
        <BehaviorCard
          headline="Vulnerability"
          details="When being vulnerable with our team, we invite open-hearted conversations and allow authenticity. It promotes showing your true self to your team and appreciates where each person stands on their journey."
        />
        <BehaviorCard
          headline="Accountability"
          details="When all other core behaviors are achieved, employees feel comfortable taking accountability for their actions. Teams and leaders must hold themselves accountable."
        />
      </Box>

      <OurHistory />
      <Testimonials
        testimonials={testimonials}
        headline="What our people think"
      />
      <CareerCultureTabs />
      <AwardsRecognitions />
      <RPMTeam />
      <OurLocations />
      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
};

export default AboutRPM;
