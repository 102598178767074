import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import RPMLogo from "../logo.svg";
import { navItems } from "../navItems";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import {
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MenuIcon from "../icons/MenuIcon.svg";
import MenuCloseIcon from "../icons/MenuCloseIcon.svg";
import FormDialog from "../components/FormDialog";

const sideNavItems = [
  {
    label: "Carriers",
    subNavItems: [
      {
        label: "Carrier Login",
        link: process.env.REACT_APP_HIGHWAY_LINK,
      },
    ],
  },
  {
    label: "Employees",
    subNavItems: [
      { label: "Employee Portal", link: "https://myrpm.rpmmoves.com" },
    ],
  },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [subNavEl, setSubNavEl] = React.useState(0);
  const handleSubNav = (index) => {
    setSubNavEl({ [index]: !subNavEl[index] });
  };

  const [sideNav, setSideNav] = React.useState(null);
  const handleOpenSideNav = (event) => {
    setSideNav(event.currentTarget);
  };
  const handleCloseSideNav = () => {
    setSideNav(null);
  };

  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ height: "80px" }}>
          <Box
            sx={{ display: { display: "flex", xs: "none", lg: "flex" }, mr: 1 }}
          >
            <Link href="/" sx={{ display: "flex", mr: 6 }}>
              <img src={RPMLogo} alt="RPM Moves logo" width="158" height="40" />
            </Link>
          </Box>

          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", lg: "none", alignItems: "center" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Link href="/">
              <img src={RPMLogo} alt="RPM Moves logo" width="158" height="40" />
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-end",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              disableRipple
              sx={{ px: 0 }}
            >
              <img src={MenuIcon} alt="toggle menu" />
            </IconButton>
            <Drawer
              id="menu-appbar"
              anchor="top"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <List sx={{ py: 0 }}>
                <ListItem
                  sx={{
                    py: 2.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Link href="/">
                    <img
                      src={RPMLogo}
                      alt="RPM Moves logo"
                      width="158"
                      height="40"
                    />
                  </Link>
                  <IconButton
                    size="large"
                    disableRipple
                    onClick={handleCloseNavMenu}
                    variant="raised"
                    sx={{
                      minWidth: 0,
                      width: "30px",
                      pt: 1.75,
                      overflow: "hidden",
                    }}
                  >
                    <img src={MenuCloseIcon} alt="close menu" />
                  </IconButton>
                </ListItem>
                {navItems.map((menu, index) => (
                  <Box key={index}>
                    <ListItem disablePadding>
                      <ListItemButton
                        href={menu.url}
                        onClick={handleCloseNavMenu}
                      >
                        <ListItemText
                          primary={menu.title}
                          primaryTypographyProps={{ fontSize: "2rem" }}
                        ></ListItemText>
                      </ListItemButton>
                      {menu.submenu && (
                        <Button
                          sx={{ minWidth: 0 }}
                          onClick={() => handleSubNav(index)}
                          id={menu.id}
                        >
                          {subNavEl[index] ? (
                            <RemoveIcon fontSize="large" />
                          ) : (
                            <AddIcon fontSize="large" />
                          )}
                        </Button>
                      )}
                    </ListItem>
                    {menu.submenu && (
                      <Collapse
                        in={subNavEl[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {menu.submenu.map((submenu, idx) => (
                          <ListItem key={idx} sx={{ m: 0, p: 0 }}>
                            <ListItemButton
                              href={submenu.url}
                              onClick={handleCloseNavMenu}
                            >
                              <ListItemText
                                primary={submenu.title}
                                primaryTypographyProps={{
                                  fontSize: "1.5rem",
                                  paddingLeft: 3,
                                }}
                              ></ListItemText>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </Collapse>
                    )}
                  </Box>
                ))}
                <ListItem disablePadding>
                  <ListItemButton sx={{ width: "100%" }}>
                    <FormDialog
                      variant="text"
                      label="Talk to us"
                      sx={{
                        display: "block",
                        width: "100%",
                        color: "neutral.grey400",
                        background: "transparent",
                        textAlign: "left",
                        paddingLeft: "0",
                        fontSize: "2rem",
                        lineHeight: 1,
                        fontWeight: 300,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem onClick={handleOpenSideNav} disablePadding>
                  <ListItemButton>
                    <ListItemText
                      primary="Login"
                      primaryTypographyProps={{ fontSize: "2rem" }}
                    ></ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Drawer>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
            {navItems.map((menu, index) => (
              <Box key={index} className="top-nav">
                <Link
                  href={menu.url}
                  underline="none"
                  sx={{ display: "flex", mx: 0, px: 2, position: "relative" }}
                >
                  <Typography variant="body2">{menu.title}</Typography>
                  {menu.submenu && (
                    <>
                      <ExpandMoreRoundedIcon
                        className="show-more"
                        sx={{ marginLeft: "8px" }}
                      ></ExpandMoreRoundedIcon>
                      <ExpandLessRoundedIcon
                        className="show-less"
                        sx={{ marginLeft: "8px" }}
                      ></ExpandLessRoundedIcon>
                    </>
                  )}
                </Link>
                {menu.submenu && (
                  <List
                    sx={{
                      position: "absolute",
                      mx: 0,
                      p: 0,
                    }}
                  >
                    {menu.submenu.map((submenu, idx) => (
                      <ListItem
                        key={idx}
                        sx={{ m: 0, p: 0, minWidth: "200px" }}
                      >
                        <Link
                          href={submenu.url}
                          underline="none"
                          sx={{ p: 2, width: "100%" }}
                        >
                          <Typography variant="body2">
                            {submenu.title}
                          </Typography>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-end",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <FormDialog variant="contained" label="Let’s Talk" />
            <Button
              variant="text"
              endIcon={<KeyboardArrowRightIcon />}
              onClick={handleOpenSideNav}
              sx={{ ml: 3 }}
            >
              Login
            </Button>
            <Drawer
              id="side-nav"
              anchor="right"
              open={Boolean(sideNav)}
              onClose={handleCloseSideNav}
            >
              <List sx={{ p: 0 }}>
                <ListItem
                  sx={{
                    pt: 2.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size="large"
                    disableRipple
                    onClick={handleCloseSideNav}
                    variant="raised"
                    sx={{
                      minWidth: 0,
                      width: "30px",
                      height: "48px",
                      pt: 1.75,
                      overflow: "hidden",
                    }}
                  >
                    <img src={MenuCloseIcon} alt="close menu" />
                  </IconButton>
                </ListItem>
                {sideNavItems.map((item, index) => (
                  <Box key={index} sx={{ mt: 3, px: 3 }}>
                    <ListItem>
                      <Typography
                        variant="h3"
                        sx={{
                          textTransform: "uppercase",
                          fontStyle: "italic",
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          width: "200px",
                          lineHeight: 1,
                        }}
                      >
                        {item.label}
                      </Typography>
                    </ListItem>
                    {item.subNavItems.map((subItem, i) => (
                      <ListItem disablePadding key={i}>
                        <ListItemButton
                          href={subItem.link}
                          onClick={handleCloseSideNav}
                        >
                          <ListItemText
                            primary={subItem.label}
                            primaryTypographyProps={{
                              fontSize: "1rem",
                              lineHeight: 1,
                              fontWeight: 400,
                            }}
                          ></ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </Box>
                ))}
              </List>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
