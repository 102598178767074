import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const SolutionHeadline = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto Condensed",
  fontSize: "1.75rem",
  lineHeight: "2rem",
  fontStyle: "italic",
  fontWeight: 700,
  margin: "0 0 16px",
}));

const FreightSolutionCard = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: "1px solid #C4C4C4",
        padding: { xs: 0, md: "40px 0 0" },
        width: { xs: "100%", md: "330px" },
        height: { xs: "auto", md: "220px" },
        margin: { xs: "8px 16px", md: "8px 0" },
      }}
    >
      {props.icon && (
        <Box
          sx={{
            width: "120px",
            height: "120px",
            background: "#EBEBEB",
            display: { xs: "none", md: "block" },
            mr: 2,
          }}
        ></Box>
      )}
      <Box sx={{ width: { xs: "100%", md: "288px" } }}>
        <SolutionHeadline>{props.headline}</SolutionHeadline>
        <Typography variant="body1" sx={{ mb: 2, pr: { xs: 0, sm: 3 } }}>
          {props.details}
        </Typography>
      </Box>
    </Box>
  );
};

export default FreightSolutionCard;
