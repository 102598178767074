import React from "react";
import { Box } from "@mui/material";

const Award = (props) => {
  return (
    <Box
      component="img"
      src={props.image}
      alt={props.altText}
      width={props.width}
      height={props.height}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        m: { xs: 1, sm: 2 },
        width: "auto",
        height: { xs: "100px", sm: "166px" },
      }}
    />
  );
};

export default Award;
