import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Grid, Link } from "@mui/material";
import { Container, Typography, Button } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box sx={{ py: 2 }}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ShipCarryTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Ship and haul with us"
          centered
        >
          <Tab label="Ship with us" {...a11yProps(0)} />
          <Tab label="Haul with us" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box>
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "100%",
              position: "relative",
            }}
            alt="Ship with us"
            src="/assets/ship-with-us.webp"
          />
        </Box>
        <Grid container maxWidth="xl">
          <Grid item xs={12} md={5}>
            <Typography variant="body2" component="p">
              Ship with us
            </Typography>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
                mt: 4,
              }}
            >
              We Alleviate Supply Chain Pressures
            </Typography>
            <Typography variant="body1" component="p" sx={{ py: 4 }}>
              We offer long-term specialized solutions to help you maximize
              profits and turn obstacles into opportunities. Our proprietary
              tools and applications give you full visibility into every load
              from origin to destination.
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button variant="contained" href="/contact" disableElevation>
                Request a Quote
              </Button>
              <Button
                variant="text"
                endIcon={<ChevronRightRoundedIcon />}
                href="/shippers"
                sx={{ ml: 3 }}
                aria-label="Click to learn more about shippers"
              >
                Learn More
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { sx: "center", lg: "flex-start" },
                flexDirection: "column",
                marginTop: { xs: "70px", md: "160px" },
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 236,
                  width: 454,
                  maxWidth: { xs: 176, md: 250, lg: 455 },
                  maxHeight: { xs: 91, md: 129, lg: 235 },
                }}
                alt="Vehicle services"
                src="/assets/vehicle-services.webp"
              />
              <Link href="/shippers/vehicles" underline="hover">
                <Typography
                  sx={{
                    color: "#173162",
                    display: "flex",
                    alignItems: "center",
                    m: { xs: 1, md: 3 },
                  }}
                >
                  Vehicle services
                  <ChevronRightRoundedIcon
                    color="primary.main"
                    sx={{ fontSize: "1.25rem", marginLeft: "4px" }}
                  ></ChevronRightRoundedIcon>
                </Typography>
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: { sx: "center", lg: "flex-start" },
                flexDirection: "column",
                marginTop: "0",
                marginLeft: { xs: "-25px", md: "-16px", lg: "-76px" },
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 235,
                  width: 454,
                  maxWidth: { xs: 176, md: 250, lg: 455 },
                  maxHeight: { xs: 91, md: 129, lg: 235 },
                }}
                alt="Freight services"
                src="/assets/freight-services.webp"
              />
              <Link href="/shippers/freight" underline="hover">
                <Typography
                  sx={{
                    color: "#173162",
                    display: "flex",
                    alignItems: "center",
                    m: { xs: 1, md: 3 },
                  }}
                >
                  Freight services
                  <ChevronRightRoundedIcon
                    color="primary.main"
                    sx={{ fontSize: "1.25rem", marginLeft: "4px" }}
                  ></ChevronRightRoundedIcon>
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "100%",
              position: "relative",
            }}
            alt="Haul with us"
            src="/assets/carry-with-us.webp"
          />
        </Box>
        <Grid container maxWidth="xl">
          <Grid item xs={12}>
            <Box sx={{ maxWidth: "520px" }}>
              <Typography variant="body2" component="p">
                Haul with us
              </Typography>
              <Typography
                variant="h2"
                component="p"
                sx={{
                  fontStyle: "italic",
                  fontWeight: 700,
                  mt: 4,
                  paddingRight: 5,
                }}
              >
                We Treat Carriers As Customers
              </Typography>
              <Typography variant="body1" component="p" sx={{ py: 4 }}>
                Our aim is to reduce empty mileage for carriers in the network.
                Keeping trucks full and drivers on the road is our main focus.
                If you're a carrier, RPM's proprietary technology and
                applications allow you to search and book available loads of
                your choice, set up dedicated lanes and give you the ability to
                customize your profile by trailer type, service type, and weekly
                lane revenue goals.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  disableElevation
                  href={process.env.REACT_APP_HIGHWAY_LINK}
                  target="_blank"
                >
                  Join Our Network
                </Button>
                <Button
                  variant="text"
                  href="/contact"
                  disableElevation
                  endIcon={<ChevronRightRoundedIcon />}
                  sx={{ ml: 3 }}
                >
                  Request a Quote
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </Box>
  );
};

export default ShipCarryTabs;
