import React from "react";
import { Box, Typography } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { styled } from "@mui/material/styles";
import FormDialog from "../components/FormDialog";

const SupportBox = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "40px 0",
  margin: "110px 0",
  flexDirection: "row",
  borderTop: "1px solid #3C3C3C",
  borderBottom: "1px solid #3C3C3C",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    padding: "72px 20px",
    backgroundColor: "#F6F6F6",
    margin: "0",
  },
}));

const SupportHome = (props) => {
  return (
    <SupportBox>
      <Typography
        variant="h2"
        component="p"
        sx={{
          color: "primary.blue800",
          fontWeight: 700,
          position: "relative",
          fontStyle: "italic",
          zIndex: 1,
        }}
      >
        We’re here to help
      </Typography>
      <Typography
        sx={{
          maxWidth: "390px",
          color: "neutral.grey400",
          zIndex: 1,
          mx: { xs: 0, lg: 5 },
          my: { xs: 2, lg: 0 },
        }}
      >
        {props.message}
      </Typography>
      <FormDialog
        label="Let’s Talk"
        sx={{ my: 2 }}
        variant="contained"
        endIcon={<ChevronRightRoundedIcon />}
      />
    </SupportBox>
  );
};

export default SupportHome;
