import React from "react";
import { Box, Grid, Link, Typography, Container } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const DriveApp = () => {
  const theme = useTheme();
  return (
    <Container
      disableGutters={useMediaQuery(theme.breakpoints.down("md"))}
      maxWidth="xl"
    >
      <Box
        sx={{
          display: "flex",
          padding: { xs: "48px 24px", lg: "24px" },
          alignItems: { xs: "center", lg: "flex-start" },
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box>
          <Box
            component="img"
            src="/assets/drive-app-lg.webp"
            alt="map"
            sx={{
              width: "100%",
              height: "auto",
              display: { xs: "none", lg: "block" },
            }}
          />
          <Box
            component="img"
            src="/assets/drive-app-sm.webp"
            alt="map"
            sx={{
              width: "100%",
              maxWidth: "586px",
              height: "auto",
              mb: 5,
              display: { xs: "block", lg: "none" },
            }}
          />
        </Box>
        <Box>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
              pt: { xs: 0, md: 12 },
            }}
          >
            The RPM Drive App makes Hauling vehicles easy
          </Typography>
          <Typography variant="body1" component="p" sx={{ mt: 3 }}>
            RPM Drive App gives vehicle carriers the opportunity to book loads,
            deliver with efficiency, and get paid quickly.
          </Typography>
          <Typography sx={{ fontWeight: 700, mt: 2 }}>
            RPM Drive App provides:
          </Typography>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <List
                sx={{
                  listStyleType: "disc",
                  px: 2,
                  py: 0,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="Immediate search for available loads" />
                </ListItem>
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="Faster Payment Processing" />
                </ListItem>
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="Fair pricing on all loads" />
                </ListItem>
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="Realtime capture for claims prevention" />
                </ListItem>
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="Reduces paperwork" />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <List
                sx={{
                  listStyleType: "disc",
                  px: 2,
                  py: 0,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="Fewer calls for location updates" />
                </ListItem>
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="Geo-fencing capabilities" />
                </ListItem>
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="GPS tracking updates" />
                </ListItem>
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="One-to-One contact support" />
                </ListItem>
                <ListItem sx={{ display: "inline-block", p: 0, m: 0 }}>
                  <ListItemText primary="Digitization of Vehicle Inspection report" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Typography variant="body1" component="p" sx={{ mt: 3 }}>
            Drive with us! Download the RPM Drive App today.
          </Typography>
          <Box
            sx={{
              mt: 3,
              display: "flex",
            }}
          >
            <Link
              href="https://play.google.com/store/apps/details?id=com.loadrpm.Ike"
              target="_blank"
            >
              <Box
                component="img"
                src="/assets/app-google.webp"
                alt="Google play app download"
                sx={{ width: 146, height: 44, m: { xs: 1, sm: 0.5 } }}
              />
            </Link>
            <Link
              href="https://apps.apple.com/us/app/rpm-drive/id1476279456"
              target="_blank"
            >
              <Box
                component="img"
                src="/assets/app-ios.webp"
                alt="IOS app download"
                sx={{ width: 146, height: 44, m: { xs: 1, sm: 0.5 } }}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default DriveApp;
