import React from "react";
import { Box, Button, Typography, Container } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const TransportationNetwork = () => {
  const theme = useTheme();
  return (
    <Container
      disableGutters={useMediaQuery(theme.breakpoints.down("md"))}
      maxWidth="xl"
    >
      <Box
        sx={{
          display: "flex",
          px: 2,
          py: { xs: 6, lg: 8 },
          alignItems: { xs: "center", lg: "top" },
          flexDirection: { xs: "column-reverse", lg: "row" },
        }}
      >
        <Box>
          <Typography variant="body2" component="p">
            Our Transport Network
          </Typography>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
              mt: 4,
            }}
          >
            We Pivot to Meet Your Needs
          </Typography>
          <Typography variant="body1" component="p" sx={{ mt: 3 }}>
            RPM’s transport network eliminates empty miles by optimizing the
            capacity of carriers, allowing shippers to continuously improve and
            promote their sustainable logistics practices.
          </Typography>
          <Button
            href="/contact"
            variant="text"
            endIcon={<ChevronRightRoundedIcon />}
            sx={{
              pl: 0,
              mt: 2,
              height: "24px",
              color: "neutral.grey400",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
            disableElevation
          >
            Request a Quote
          </Button>
        </Box>
        <Box
          component="img"
          src="/assets/map.webp"
          alt="map"
          sx={{
            width: "100%",
            maxWidth: "900px",
            height: "auto",
            mb: 5,
          }}
        />
      </Box>
    </Container>
  );
};

export default TransportationNetwork;
