import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Moment from "moment";
import { Link } from "@mui/material";

const ArticleCard = (props) => {
  return (
    <Box
      sx={{
        width: "408px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.image && (
        <Link href={"/" + props.type + "/" + props.slug} underline="none">
          <Box
            component="img"
            src={"https://cdn.wisefront.com/" + props.image.large_path}
            width="408"
            height="248"
            alt={props.title}
            sx={{ width: "100%", maxWidth: "408px", height: "auto" }}
          />
        </Link>
      )}
      <Box sx={{ py: 3, display: "flex", flexDirection: "column" }}>
        <Link href={"/" + props.type + "/" + props.slug} underline="hover">
          <Typography component="p" variant="h5" color="primary.blue700">
            {props.title}
          </Typography>
        </Link>
        {props.excerpt && (
          <Typography component="p" variant="body5" sx={{ mb: 1 }}>
            {props.excerpt}
          </Typography>
        )}
        <Typography component="p" variant="body5">
          {Moment(props.publishedDate).format("D MMM YYYY")}
        </Typography>
      </Box>
    </Box>
  );
};

export default ArticleCard;
