import React from "react";
import { Helmet } from "react-helmet";
import { Container, Box, Typography, Button } from "@mui/material";
import Testimonials from "../components/Testimonials/Testimonials";
import SupportInternal from "../modules/SupportInternal";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";
import Carousel from "../components/Carousel/Carousel";
import VehicleSpecializedSolutions from "../modules/VehicleSpecializedSolutions";
import CaseStudies from "../modules/CaseStudies";
import AwardsRecognitions from "../modules/AwardsRecognitions";
import TopHero from "../modules/TopHero";

const carouselItems = [
  {
    title: "Non-Asset Based Provider",
    heading: "We’re Not Your Typical Broker",
    details:
      "The combination of our experience, our network, and our proprietary technology allows us the ability to solve our vehicle shippers’ complex challenges. We build capacity networks around our customers’ complex needs.",
  },
  {
    title: "Real-time Updates",
    heading: "Transparency & Visibility",
    details:
      "Our proprietary technology provides full shipment visibility into every load, allowing customers to see exactly where a vehicle is in transit.",
  },
  {
    title: "Carrier Compliance",
    heading: "Delivering Quality",
    details:
      "We build quality with our network, thus minimizing risk, reducing damage, and ultimately ensuring the right driver is handling the right load, every time.",
  },
  {
    title: "OEM Quality",
    heading: "Process & Procedures",
    details:
      "RPM has developed a proprietary process to identify carriers in the network who can support OEMs expectations around quality.",
  },
  {
    title: "Non-traditional Capacity",
    heading: "Fast & Flexible",
    details:
      "We have a variety of vetted carriers we leverage and aggregate who can handle capacity with non traditional equipment.",
  },
  {
    title: "Chain of Custody",
    heading: "The RPM Drive App",
    details:
      "Through our proprietary RPM Drive App, we ensure an end-to-end chain of custody to deliver quality our OEM shippers expect.",
  },
  {
    title: "Onsite Support",
    heading: "Spot and Contract",
    details:
      "RPM deploys boots on the ground to understand the processes and procedures at our shippers' facilities, including ports, railheads, and manufacturing plants.",
  },
];

const testimonials = [
  {
    description: `“We love to get things done fast. That’s why we love working with RPM.”`,
    author: "Loki Williams Bright",
    authorTitle: "Purchasing & Logistics Manager",
  },
  {
    description: `“Shipping with RPM was seamless, everything arrived perfect and on-time. I will definitely use them for future transport needs.”`,
    author: "Landon M.",
    authorTitle: "Supply Chain & Logistics Manager",
  },
  {
    description: `“RPM really understands my transportation needs and are able to be flexible. They have yet to say “no”, regardless of how complex my request.”`,
    author: "Jeremiah D.",
    authorTitle: "Logistics Coordinator",
  },
];

const heroItems = ["/assets/vehicles-hero-slide-1.webp"];

const Vehicles = () => {
  return (
    <>
      <Helmet>
        <title>
          RPM Vehicles | Work With Professional Auto Transportation Specialists
        </title>
        <meta
          name="description"
          content="RPM gives their customers refreshing clarity on car shipping cost and car transport services. Click here to find out how much to ship a car near you."
        />
        <meta
          property="og:title"
          content="RPM Shippers | Work With Professional Auto Transportation Specialists"
        />
        <meta
          property="og:description"
          content="RPM gives their customers refreshing clarity on car shipping cost and car transport services. Click here to find out how much to ship a car near you."
        />
      </Helmet>
      <TopHero
        direction="left"
        heroItems={heroItems}
        tagline="We Move Hundreds of Thousands of Vehicles Per Year"
        taglineVariant="h2"
        copy="Vehicle Transportation is a complex world and we have a scalable
          network that allows us to deliver unique solutions for our customers'
          ever changing needs."
        ctaText="Let’s Talk"
        ctaLink="/contact"
        ctaTarget="_self"
      />
      <ModuleHeadline headline="WE’RE TRUSTED BY THOUSANDS OF COMPANIES ACROSS THE GLOBE" />
      <Carousel items={carouselItems} />
      <VehicleSpecializedSolutions />
      <CaseStudies />
      <Testimonials testimonials={testimonials} />
      <Container maxWidth="lg" disableGutters>
        <Box
          sx={{
            display: "flex",
            padding: "36px 0",
            alignItems: { xs: "center", lg: "top" },
            justifyContent: "center",
            flexDirection: { xs: "column-reverse", lg: "row" },
          }}
        >
          <Box sx={{ maxWidth: { xs: "100%", lg: "434px" }, py: 5, px: 2 }}>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
              }}
            >
              RPM Moves You
            </Typography>
            <Typography variant="body1" sx={{ py: 3 }}>
              RPM Moves is RPM's business-to-consumer (B2C) auto transport
              service, specializing in privately owned vehicles. This
              consumer-facing service leverages our expansive network of
              carriers to handle consumer open, enclosed, and expedited moves
              throughout the continental U.S. RPM Moves offers a quick and easy
              quoting system that makes shipping your personal vehicle a breeze.
            </Typography>
            <Button href="/individual" variant="contained" disableElevation>
              More about RPM Moves
            </Button>
          </Box>

          <Box
            component="img"
            src="/assets/RPMMoves_Logo.png"
            alt="map"
            sx={{
              width: "100%",
              maxWidth: "749px",
              height: "auto",
            }}
          />
        </Box>
      </Container>
      <AwardsRecognitions />
      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
};

export default Vehicles;
