import * as React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Typography, Button } from "@mui/material";

const CultureTab = () => {
  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Grid
        container
        maxWidth="xl"
        sx={{
          flexDirection: { xs: "column-reverse", md: "row" },
          height: { xs: "auto", lg: "520px", margin: "auto" },
        }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: "flex",
            height: { xs: "248px", md: "620px" },
            position: "relative",
            justifyContent: "center",
            mt: { xs: "45px", md: 0 },
            mb: { xs: "126px", md: 0 },
          }}
        >
          <Box
            component="img"
            sx={{
              height: 479,
              width: 713,
              maxWidth: { xs: 273, md: 713 },
              maxHeight: { xs: 184, md: 479 },
              position: { xs: "relative", md: "absolute" },
              top: "0",
              right: { xs: "-62px", md: "218px" },
            }}
            alt="Careers"
            src="/assets/culture-1.webp"
          />
          <Box
            component="img"
            sx={{
              height: 479,
              width: 570,
              maxWidth: { xs: 219, md: 570 },
              maxHeight: { xs: 184, md: 479 },
              position: { xs: "relative", md: "absolute" },
              top: { xs: "63px", md: "75px" },
              right: { xs: 0, md: "-128px" },
            }}
            alt="Careers"
            src="/assets/culture-2.webp"
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ px: 3 }}>
          <Typography
            variant="body2"
            component="p"
            sx={{ mt: { xs: 7, lg: 9 } }}
          >
            Culture
          </Typography>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
              mt: 4,
            }}
          >
            Our culture
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ py: 4, maxWidth: "424px" }}
          >
            Over the years, we have won numerous culture awards for one simple
            reason—we put our people first. We prioritize the well-being of our
            employees and support them at all levels within the organization to
            ensure they reach their fullest potential.
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              href="/culture"
              aria-label="Click to view our culture"
              disableElevation
            >
              Our Culture
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CultureTab;
