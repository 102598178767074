import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Container, Grid } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function Carousel(props) {
  const steps = props.items;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ pb: 9, position: "relative" }}
      disableGutters
    >
      <Box
        sx={{
          width: "184px",
          position: "absolute",
          right: { xs: "50%", lg: "20px" },
          zIndex: 1,
          bottom: { xs: 0, lg: "initial" },
          transform: { xs: "translateX(50%)", lg: "none" },
        }}
      >
        <MobileStepper
          variant="dots"
          sx={{ background: "transparent" }}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              aria-label="navigate to next"
              sx={{
                width: "64px",
                height: "64px",
                borderRadius: "32px",
                backgroundColor: "neutral.grey200",
                mx: 1.25,
                mt: 2,
              }}
            >
              {theme.direction === "rtl" ? (
                <ArrowBackIcon fontSize="large" />
              ) : (
                <ArrowForwardIcon fontSize="large" />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              aria-label="navigate to previous"
              sx={{
                width: "64px",
                height: "64px",
                borderRadius: "32px",
                backgroundColor: "neutral.grey200",
                mx: 1.25,
                mt: 2,
              }}
            >
              {theme.direction === "rtl" ? (
                <ArrowForwardIcon fontSize="large" />
              ) : (
                <ArrowBackIcon fontSize="large" />
              )}
            </Button>
          }
        />
      </Box>
      <Grid container sx={{}}>
        <Grid
          item
          xs={12}
          lg={7}
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            position: "relative",
            p: 0,
          }}
        >
          <Box
            component="img"
            src="/assets/vehicles-carousel.webp"
            alt="About RPM"
            sx={{
              width: "100%",
              maxWidth: "817px",
              height: "auto",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          sx={{
            display: "flex",
            alignItems: "center",
            py: { xs: 4, lg: 0 },
            px: { xs: 2, lg: 0 },
          }}
        >
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            interval={6000}
            enableMouseEvents
          >
            {steps.map((step, index) => (
              <Box key={index} sx={{ maxWidth: { xs: "100%", lg: "460px" } }}>
                <Typography variant="body2" sx={{ fontSize: ".875rem", mb: 4 }}>
                  {steps[activeStep].title}
                </Typography>
                <Typography
                  variant="h2"
                  component="p"
                  color="primary.main"
                  sx={{
                    maxWidth: "740px",
                    fontStyle: "italic",
                    fontWeight: 700,
                  }}
                >
                  {steps[activeStep].heading}
                </Typography>
                <Typography color="primary.main" variant="body1" sx={{ mt: 3 }}>
                  {steps[activeStep].details}
                </Typography>
                <Typography
                  color="primary.main"
                  variant="body5"
                  sx={{ fontWeight: 700 }}
                >
                  {steps[activeStep].authorTitle}
                </Typography>
              </Box>
            ))}
          </AutoPlaySwipeableViews>
        </Grid>
      </Grid>
    </Container>
  );
}
