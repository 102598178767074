import React from "react";
import { Box, Container } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import StaticFeatureCard from "../components/StaticFeatureCard/StaticFeatureCard";
import Truck from "../icons/Truck.svg";
import Sustainable from "../icons/Sustainable.svg";
import HandShake from "../icons/HandShake.svg";
import RobustNetwork from "../icons/RobustNetwork.svg";

const cards = [
  {
    header: "01",
    footer: "Bi-Continental Relationships",
    icon: HandShake,
  },
  {
    header: "02",
    footer: "Sustainable Practices",
    icon: Sustainable,
  },
  {
    header: "03",
    footer: "Agile Business Model",
    icon: RobustNetwork,
  },
  {
    header: "04",
    footer: "Niche Equipment Types",
    icon: Truck,
  },
];

const ShippersFeatureCards = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        my: 4,
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {cards.map((card, i) => (
          <StaticFeatureCard
            key={i}
            header={card.header}
            footer={card.footer}
            icon={card.icon}
          />
        ))}
      </Box>
      <Carousel
        interval={5000}
        swipe={false}
        navButtonsAlwaysVisible
        sx={{ textAlign: "center", display: { xs: "block", sm: "none" } }}
        navButtonsWrapperProps={{
          style: {
            display: "flex",
            alignItems: "center",
          },
        }}
        navButtonsProps={{
          style: {
            width: "40px",
            height: "40px",
            color: "#111827",
            backgroundColor: "#F6F6F6",
          },
        }}
        indicatorContainerProps={{
          style: {
            margin: "10px 0",
          },
        }}
        indicatorIconButtonProps={{
          style: {
            width: "16px",
            height: "16px",
            color: "#C4C4C4",
            backgroundColor: "#ffffff",
            margin: "0 12px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            width: "16px",
            height: "16px",
            color: "#173162",
            backgroundColor: "#173162",
          },
        }}
      >
        {cards.map((card, i) => (
          <StaticFeatureCard
            key={i}
            header={card.header}
            footer={card.footer}
            icon={card.icon}
          />
        ))}
      </Carousel>
    </Container>
  );
};

export default ShippersFeatureCards;
