import React from "react";
import Box from "@mui/material/Box";

const CultureHero = () => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        backgroundImage: `url(${"/assets/culture-hero.webp"})`,
        backgroundSize: { xs: "auto 380px", lg: "2400px auto" },
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 0",
        height: { xs: 338, lg: 528 },
      }}
    ></Box>
  );
};

export default CultureHero;
