import { Box, Container, Typography, Button } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { HeadingTwo, HeadingThree, HeadingFour } from "../styles/UtilityStyles";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>RPM | Terms & Conditions</title>
        <meta name="description" content="Terms & Conditions" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container maxWidth="md">
        <Box sx={{ py: 8, position: "relative" }}>
          <HeadingTwo>Terms & Conditions</HeadingTwo>
          <Button
            href="/assets/General_Terms_Cnditions_211020.pdf"
            variant="outlined"
            target="_blank"
            endIcon={<SystemUpdateAltIcon />}
            sx={{
              position: { xs: "relative", md: "absolute" },
              right: 0,
              top: { xs: "6px", md: "66px" },
            }}
          >
            Download PDF Version
          </Button>
          <HeadingFour>For Broker-Shipper Relationship</HeadingFour>
          <Typography variant="body1">
            RPM FREIGHT SYSTEMS, LLC, a Michigan limited liability company,
            holds authority from the U.S. Federal Motor Carrier Safety
            Administration (“FMCSA”), as a property transportation broker under
            FMCSA License Number MC 767565 and is hereafter referred to as
            “Broker.”
            <br />
            <br />
            Broker’s services for its customers (“Shipper(s)”) include, but are
            not limited to, arranging/brokering full truckload (“FTL”), less
            than truckload (“LTL”), and intermodal (via a combination of motor
            carrier and rail) transportation.
            <br />
            <br />
            Broker and Shipper (also sometimes referred to individually as a
            “Party”, and collectively as the “Parties”) agree that their
            relationship with respect to shipments tendered by Shipper to Broker
            shall be governed by the following terms and conditions
            (“Agreement”):
          </Typography>

          <HeadingFour>Tender of Shipments</HeadingFour>
          <Typography variant="body1">
            Shipper agrees to tender or cause to be tendered by Shipper on its
            own behalf or as agent for and on behalf of Shipper’s customer(s),
            one or more shipments to Broker for the purpose of having Broker
            arrange the transportation of the shipments by motor carriers to be
            selected by Broker. Shipper and Broker agree that this Agreement
            does not restrict Shipper from tendering shipments to other property
            transportation brokers or directly to motor carriers.
          </Typography>

          <HeadingFour>Brokerage Services</HeadingFour>
          <Typography variant="body1">
            For all shipments tendered by Shipper to Broker and accepted by
            Broker, Broker agrees to arrange for the pick-up, transport and
            delivery of the shipments, as Shipper may reasonably request,
            exclusively by motor carriers that hold the proper governmental
            authority to perform the requested service(s). In arranging
            transportation services for Shipper, Broker shall not be responsible
            for packaging, handling or loading of shipments, which, instead,
            shall be the responsibility of Shipper and/or the underlying motor
            carrier selected by Broker to transport the shipments. Every
            shipment handled by Broker for or on behalf of Shipper while this
            Agreement is in effect will be deemed tendered to Broker under this
            Agreement. Broker has the sole right to select the motor carriers
            used to perform the transportation services, and Broker is solely
            authorized to make the necessary transportation arrangements with
            regard to each shipment tendered by Shipper to Broker. In performing
            brokerage services for Shipper, Broker shall only select motor
            carriers that meet the following criteria:
          </Typography>

          <HeadingFour>FMCSA Authority</HeadingFour>
          <Typography variant="body1">
            Motor carriers selected by Broker shall have and maintain the proper
            and necessary authority from the FMCSA and any applicable state
            agency to perform transportation services in intrastate, interstate
            and/or foreign commerce.
          </Typography>

          <HeadingFour>Safety</HeadingFour>
          <Typography variant="body1">
            Broker shall only select a motor carrier to transport a shipment if:{" "}
            <br />
            <br />
            (A) (i) at the time the shipment is to be transported the motor
            carrier has a safety rating or determination from the U.S.
            Department of Transportation (“U.S. DOT”)/FMCSA that is either
            “Satisfactory,” “Unrated,” “None,” “Continue To Operate” or similar
            safety rating issued by the FMCSA, or <br />
            <br />
            (ii) the motor carrier has an FMCSA safety rating or fitness
            determination of “Conditional” or the equivalent, but has furnished
            evidence satisfactory to Broker regarding corrective action taken by
            the carrier to fully correct the safety deficiency(ies) which
            resulted in the carrier receiving such rating, and <br />
            <br />
            (B) the motor carrier has agreed to perform transportation of the
            shipment in full compliance with all applicable safety laws and
            requirements.
          </Typography>

          <HeadingFour>Insurance</HeadingFour>
          <Typography variant="body1">
            Motor carriers selected by Broker shall maintain insurance of the
            kind and in the amounts as required in Section 13 of this Agreement.
          </Typography>

          <HeadingFour>Cargo Loss or Damage</HeadingFour>
          <Typography variant="body1">
            Broker shall require that motor carriers selected by Broker agree to
            be liable to Shipper for damages, including cargo loss or damage, as
            provided in Section 12 of this Agreement.
          </Typography>

          <HeadingFour>Shipment Schedules</HeadingFour>
          <Typography variant="body1">
            Motor carriers selected by Broker shall be required to perform
            timely and reliable pick-up and delivery of all shipments in
            accordance with reasonable schedules communicated in writing by
            Shipper to Broker and/or Broker’s arranged motor carriers providing
            the actual, physical transportation of such shipments.
          </Typography>

          <HeadingFour>Performance of Services</HeadingFour>
          <Typography variant="body1">
            Broker will arrange the dispatch and transport of each shipment
            tendered to it by Shipper. Broker will provide Shipper with prompt
            notification by telephone or electronic communication when this
            obligation cannot be met for any reason. Broker will communicate any
            delivery schedule requested by Shipper to each motor carrier that it
            engages to transport any particular shipment of Shipper. Broker will
            require that its selected motor carriers perform the actual physical
            transportation of the shipment, and agree not to “double broker”
            such transportation to another motor carrier.
          </Typography>

          <HeadingFour>Hazardous Materials</HeadingFour>
          <Typography variant="body1">
            Shipper and Broker shall comply with all applicable laws and
            regulations relating to the transportation of hazardous materials as
            defined in 49 C.F.R. Parts 171 et seq. to the extent that any
            shipments constitute hazardous materials. Shipper is obligated to
            inform Broker immediately if any shipments constitute hazardous
            materials. Shipper shall defend, indemnify and hold Broker harmless
            from any penalties or liability of any kind, including reasonable
            attorneys’ fees, arising out of Shipper’s failure to comply with the
            requirements set forth in this Section.
          </Typography>

          <HeadingFour>Independent Contractor</HeadingFour>
          <Typography variant="body1">
            Broker’s relationship to Shipper is that of an independent
            contractor, not an agent or employee, and nothing in this Agreement
            shall be construed as establishing an employment relationship,
            partnership or joint venture between the Parties. Broker shall make
            arrangements that it deems appropriate for the transportation of
            shipments tendered to it by Shipper under this Agreement. Neither
            Party shall be liable for any obligation incurred by the other,
            except as expressly set forth in this Agreement.
          </Typography>

          <HeadingFour>Compliance with Law</HeadingFour>
          <Typography variant="body1">
            Shipper shall comply with any and all federal, state and local laws
            or regulations applicable to its tendering of shipments for
            transportation pursuant to the terms of this Agreement and to its
            performance obligations hereunder.
          </Typography>

          <HeadingFour>Indemnification</HeadingFour>
          <Typography variant="body1">
            Shipper shall be responsible for any wrongful or negligent acts,
            omissions and/or violations committed by Shipper, its employees
            and/or agents, and shall defend, indemnify and hold Broker harmless
            from and against any and all taxes, penalties, fines, damages,
            sanctions, losses, assessments, liabilities, claims, costs and other
            expenses (including reasonable attorneys’ fees) (collectively,
            “Losses”) resulting from or relating thereto; provided, however,
            that Shipper shall not be responsible to the extent any such Losses
            are caused by any wrongful or negligent acts, omissions or
            violations by Broker, its employees and/or its agents.
            <br />
            <br />
            Broker shall be responsible for any wrongful or negligent acts,
            omissions and/or violations by Broker, its employees and/or agents,
            and shall defend, indemnify and hold Shipper harmless from and
            against any and all Losses resulting from or relating thereto;
            provided, however, that Broker shall not be responsible to the
            extent any such Losses are caused by any wrongful or negligent acts,
            omissions or violations by Shipper, its employees and/or its agents.
          </Typography>

          <HeadingFour>Compensation to Broker</HeadingFour>
          <Typography variant="body1">
            Compensation for Broker’s services shall be paid by Shipper to
            Broker for all shipments tendered by Shipper to Broker in accordance
            with rates and/or charges agreed to by Broker and Shipper through
            written agreement, email correspondence, facsimile transmission,
            text message, other electronic transmission or verbal communication.
          </Typography>

          <HeadingFour>Bill of Lading and Receipt</HeadingFour>
          <Typography variant="body1">
            Broker shall require each motor carrier it selects to transport a
            shipment tendered by Shipper to Broker:
            <br />
            1. to issue a bill of lading at origin evidencing receipt of the
            shipment tendered to the motor carrier, and
            <br />
            2. to obtain a receipt for delivery of the shipment from the
            consignee or other party accepting delivery.
          </Typography>

          <HeadingFour>Payment to Motor Carriers</HeadingFour>
          <Typography variant="body1">
            Broker shall be solely and exclusively liable and responsible for
            the payment of rates and charges to motor carriers engaged by Broker
            that relate to the transportation of shipments tendered by Shipper
            to Broker pursuant to this Agreement. Shipper’s sole obligation with
            regard to the payment of transportation charges for services
            provided under or in relation to this Agreement is to pay Broker as
            required by Section 8 and Section 9 of this Agreement.
          </Typography>

          <HeadingThree>Cargo Liability</HeadingThree>

          <HeadingFour>Liability Limits</HeadingFour>
          <Typography variant="body1">
            The motor carrier arranged by Broker shall have liability for cargo
            loss or damage. Except as otherwise provided in this Section 12,
            recovery (as opposed to liability) for cargo loss or damage may be
            limited to a maximum of one hundred thousand dollars ($100,000) per
            shipment or per consolidated shipments – unless insurance coverage
            for increased cargo value has been requested by Shipper, and Broker
            has advised Shipper in writing prior to tender of the shipment(s)
            that it has arranged higher cargo loss or damage coverage. To the
            extent that multiple shipments or consolidated shipments are
            tendered by Shipper and, at the specific written request of Shipper,
            are transported at the same time and in the same vehicle, such
            multiple shipments or consolidated shipments shall be considered a
            “single shipment” for the purposes of this Section.
          </Typography>

          <HeadingFour>Liability of Motor Carrier</HeadingFour>
          <Typography variant="body1">
            The motor carrier arranged by Broker to transport Shipper’s property
            shall be liable as a motor carrier under 49 U.S.C. § 14706 and as a
            motor carrier under common law to Shipper with respect to damages
            for loss of or damage to any shipment tendered by Shipper pursuant
            to this Agreement. The motor carrier arranged by Broker shall not be
            liable for any loss or damage caused by any act of God, the public
            enemy, authority of law, the act or omission of Shipper, or due to
            the inherent vice of the goods shipped.
          </Typography>

          <HeadingFour>Processing of Cargo Loss or Damage Claims</HeadingFour>
          <Typography variant="body1">
            In processing of cargo loss or damage claims, the motor carrier
            selected by Broker shall comply with 49 C.F.R. § 370.1, et seq. and
            any amendments and/or any other applicable regulations adopted by
            the U.S. DOT/FMCSA, or any applicable state regulatory agency.
          </Typography>

          <HeadingFour>Insurance</HeadingFour>
          <Typography variant="body1">
            Broker shall require all motor carriers to have and maintain public
            liability and property damage insurance in the amount of one million
            dollars ($1,000,000) and cargo loss or damage insurance in the
            amount of one hundred thousand dollars ($100,000) per shipment.
            Broker agrees to maintain a complete and up to date file of all
            Certificates of Insurance evidencing public liability and property
            damage insurance policies, and cargo loss or damage insurance
            policies of all motor carriers that it contracts with regarding
            transportation arranged for or on behalf of Shipper.
          </Typography>

          <HeadingFour>Exclusion of Certain Damages</HeadingFour>
          <Typography variant="body1">
            Neither Party shall be liable for, and each Party hereby waives any
            right to, any special, indirect, incidental, consequential or
            punitive damages, including lost profits, lost fees, lost business,
            loss of use, costs associated with business interruptions or like
            damages (the “Excluded Damages”), whether based upon contract, tort
            or any other legal theory, resulting from or in any way connected
            with the performance by either Party of their respective covenants
            and agreements pursuant to this Agreement. Neither Party shall be
            liable to the other for the Excluded Damages, whether foreseeable or
            not, and even if such Party has been advised or otherwise has
            knowledge of the possibility of the Excluded Damages.
          </Typography>

          <HeadingFour>Notice</HeadingFour>
          <Typography variant="body1">
            Any notice and other communication relating to this Agreement shall
            be in writing and be sent:
            <br />
            (a) by certified mail, return receipt requested, postage prepaid,
            <br />
            (b) by nationally recognized overnight courier service to the
            addresses stated above,
            <br />
            (c) by fax, with proof of receipt by the intended recipient,
            <br />
            (d) by email with proof of receipt by the intended recipient, or
            <br />
            (e) in such other manner or to such other address as shall have been
            designated by the Party to which such notice or other communication
            is to be given. All such notices and other communications will be
            deemed to have been given and received
            <br />
            (1) in the case of personal delivery, on the date of such delivery,
            <br />
            (2) in the case of facsimile or e-mail transmission on the date of
            transmission if sent on a business day (or if sent on other than a
            business day, on the next business day after the date sent),
            <br />
            (3) in the case of delivery by nationally recognized overnight
            courier service, on the business day following dispatch if sent by
            guaranteed next day delivery, or
            <br />
            (4) in the case of mailing, on the third business day following such
            mailing.
          </Typography>

          <HeadingFour>Non-Disclosure of Information</HeadingFour>
          <Typography variant="body1">
            Broker and Shipper agree to keep confidential any information
            provided by the other Party relating to such Party’s operations or
            business activities, including, but not limited to:
            <br />
            (i) the names of motor carriers, customers, suppliers and vendors,
            and
            <br />
            (ii) freight rates and charges (collectively “Confidential
            Information”). For purposes hereof, Confidential Information shall
            also include the terms and conditions of this Agreement and any
            amendments or attachments hereto. Each Party agrees to hold all such
            Confidential Information in confidence and shall not use any such
            Confidential Information other than for the benefit of the other
            Party or in performance of its obligations under this Agreement.
            <br />
            <br />
            The restriction against disclosure of Confidential Information as
            specified in this Section 17 shall not apply to information which
            <br />
            (i) was already known prior to the time it was imparted to the
            receiving Party by the other Party,
            <br />
            (ii) is available or becomes generally available to the public other
            than through a breach of this Agreement by the receiving Party,
            <br />
            (iii) is acquired or received by the receiving Party rightfully and
            without confidential limitation from a third party, or
            <br />
            (iv) is independently developed by the receiving Party without
            breach of this Agreement. If either Party becomes legally required
            to disclose Confidential Information, or any part thereof, that
            Party will give the other prompt notice of such requirement. If the
            non-disclosing Party waives compliance with any of the terms of this
            Agreement or is unable to obtain a protective order or other
            appropriate remedy with respect to such disclosure of Confidential
            Information, then the disclosing Party shall disclose only that
            portion of the Confidential Information necessary to ensure
            compliance with such legal requirement.
          </Typography>

          <HeadingFour>Governing Law</HeadingFour>
          <Typography variant="body1">
            This Agreement shall be governed by and construed in accordance with
            the laws of the State of Michigan except and only to the extent that
            any mandatory federal law is applicable to this Agreement.
          </Typography>

          <HeadingFour>Assignment</HeadingFour>
          <Typography variant="body1">
            Neither Party shall assign this Agreement or any interest in this
            Agreement, without the prior written consent of the other Party,
            except if notice is provided and the assignment is to a parent,
            subsidiary or affiliated entity of the assigning Party. Subject to
            the foregoing, this Agreement shall inure to the benefit of and be
            binding upon, the successors and assigns of the Parties.
          </Typography>

          <HeadingFour>Entire Agreement</HeadingFour>
          <Typography variant="body1">
            Neither Party has rights, warranties or conditions expressed or
            implied, statutory or otherwise, other than those contained in this
            Agreement. This Agreement contains the entire agreement between
            Broker and Shipper and may only be modified or rescinded by a
            writing signed by both Parties. Any document submitted by Shipper to
            Broker confirming its intention to tender a shipment to Broker will
            be deemed to constitute a confirmation and acceptance of this
            Agreement, even if such document states terms in addition to or
            different from those in this Agreement. All agreements between
            Broker and Shipper will be solely under the terms and conditions of
            this Agreement, and Broker objects to any and all such additional or
            different terms contained in any document submitted to Broker by
            Shipper. Any execution by Broker of any other document submitted by
            Shipper in connection with the tender of any shipment does not
            constitute acceptance of or agreement to any terms and conditions in
            addition to or different from those contained in this Agreement, but
            will constitute only acknowledgment of receipt of such document.
          </Typography>

          <HeadingFour>Waiver</HeadingFour>
          <Typography variant="body1">
            No waiver of any provision of this Agreement will be binding unless
            in writing signed by an authorized representative of the Party
            against whom the waiver is asserted, and unless expressly made
            generally applicable, will apply only to the specific case for which
            the waiver is given. Failure of either Party to insist upon strict
            performance of this Agreement will not be construed as a waiver of
            any term or condition of the Agreement.
          </Typography>

          <HeadingFour>Severability</HeadingFour>
          <Typography variant="body1">
            If any provision of this Agreement is or becomes invalid or
            unenforceable, that provision (to the extent invalid or
            unenforceable) shall be deemed amended or reformed to the extent
            required to render it valid and enforceable, and the remainder of
            this Agreement shall be unaffected and shall continue in effect.
          </Typography>

          <HeadingFour>Disputes</HeadingFour>
          <Typography variant="body1">
            In the event of a dispute between the Parties arising out of or
            relating to this Agreement, which the Parties are unable to resolve
            within fifteen (15) days after the dispute has arisen, shall be
            subject to final and binding arbitration as set forth in this
            Section 23. Arbitration proceedings shall be conducted under the
            rules of the Transportation ADR Council (“TADR”), or the American
            Arbitration Association (“AAA”), at Broker’s sole discretion.
            Arbitration proceedings shall be started within eighteen (18) months
            from the date of the occurrence from which the dispute allegedly
            arose. Upon agreement of the Parties, arbitration proceedings may be
            conducted outside of the administrative control of the TADR or AAA.
            The decision of the arbitrator(s) shall be binding and final, and
            the award of the arbitrator(s) may be entered as judgment in any
            court of competent jurisdiction. The prevailing Party shall be
            entitled to recovery of costs, expenses and reasonable attorney’s
            fees as well as those costs and fees incurred in any action for
            injunctive relief, or in the event further legal action is taken to
            enforce the award of the arbitrator(s). Arbitration proceedings
            shall be conducted at the office of TADR or the AAA nearest Detroit,
            Michigan or such other place as mutually agreed upon in writing by
            the Parties or directed by the acting arbitration organization.
            Notwithstanding the foregoing, either Party may apply to a court of
            competent jurisdiction for injunctive relief. Venue for any such
            action against Broker shall be Oakland County, Michigan. The
            arbitration provisions of this Section 23 shall not apply to
            enforcement of the award of any such arbitration.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Terms;
