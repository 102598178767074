import { Box, Button, Grid, Typography, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMediaQuery, useTheme } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #C4C4C4",
  borderRadius: 0,
}));

const JobPositions = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const theme = useTheme();
  const isMediumSize = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    fetch(
      "https://api.resumatorapi.com/v1/jobs/status/open?apikey=eQRhhnIwZt9QOdLX8DwW1vSlwUG6YQDK"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <>
        <Container maxWidth="xl" disableGutters={isMediumSize ? true : false}>
          <Typography
            variant="h4"
            component="p"
            id="available-positions"
            sx={{
              fontStyle: "italic",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 9,
              textAlign: "center",
              mb: 4,
              px: 2,
            }}
          >
            Fetching job positions...
          </Typography>
        </Container>
      </>
    );
  } else {
    if (items.length) {
      return (
        <>
          <Container maxWidth="xl" disableGutters={isMediumSize ? true : false}>
            <Typography
              variant="h2"
              id="available-positions"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 9,
                textAlign: "center",
                mb: 4,
                px: 2,
              }}
            >
              Available Positions
            </Typography>
            <Grid container spacing={2} sx={{ px: 2 }}>
              {items.slice(0, 10).map((item) => (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  lg={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Item
                    elevation={0}
                    sx={{
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: { xs: "flex-start", md: "center" },
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="p"
                      sx={{
                        fontStyle: "italic",
                        fontWeight: 700,
                        display: "inline-flex",
                        maxWidth: { xs: "100%", md: "70%" },
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Button
                      disableElevation
                      href={`https://rpm.applytojob.com/apply/${item.id}/`}
                      variant="text"
                      target="_blank"
                      endIcon={<ArrowForwardIcon fontSize="small" />}
                      sx={{ fontSize: "small" }}
                    >
                      Apply now
                    </Button>
                  </Item>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                href="https://rpm.applytojob.com/apply/"
                target="_blank"
                disableElevation
                sx={{ margin: "60px auto" }}
              >
                View All Available Positions
              </Button>
            </Box>
          </Container>
        </>
      );
    } else {
      return (
        <Container maxWidth="xl" disableGutters={isMediumSize ? true : false}>
          <Typography
            variant="h4"
            component="p"
            id="available-positions"
            sx={{
              fontStyle: "italic",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 9,
              textAlign: "center",
              mb: 4,
              px: 2,
            }}
          >
            Error finding current job positions
          </Typography>
        </Container>
      );
    }
  }
};

export default JobPositions;
