import React from "react";
import Box from "@mui/material/Box";
import { Button, Container, Grid, Typography } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import PlayButton from "../components/PlayButton/PlayButton";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeroBox = styled(Box)(({ theme }) => ({
  height: 492,
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    height: "auto",
    padding: "0",
  },
}));

const HeroBoxTop = styled(Box)(({ theme }) => ({
  margin: "72px 0 0 0",
  maxWidth: "430px",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
    margin: 0,
    padding: "56px 24px",
    height: "auto",
  },
}));

const CaseStudies = () => {
  const theme = useTheme();
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Container disableGutters={useMediaQuery(theme.breakpoints.down("md"))}>
        <HeroBox>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <HeroBoxTop>
                <Typography variant="body2" component="p">
                  RPM Case Studies
                </Typography>
                <Typography
                  variant="h2"
                  component="p"
                  sx={{
                    fontStyle: "italic",
                    fontWeight: 700,
                    mt: 4,
                  }}
                >
                  Transporting Deep Weather Testing Vehicles for Rivian
                </Typography>
                <Typography variant="body1" component="p" sx={{ mt: 3 }}>
                  In the dead of winter, RPM was asked to transport deep weather
                  testing vehicles to Fort Greely, Alaska for one of our largest
                  OEM customers, Rivian. Check out the video to learn how we
                  successfully picked up and transported the vehicles across
                  “The Last Frontier.”
                </Typography>
                <Button
                  href="/contact"
                  variant="text"
                  endIcon={<ChevronRightRoundedIcon />}
                  sx={{
                    pl: 0,
                    mt: 2,
                    height: "24px",
                    color: "neutral.grey400",
                    "&:hover": {
                      backgroundColor: "transparent",
                      textDecoration: "underline",
                    },
                  }}
                  disableElevation
                >
                  Request a Quote
                </Button>
              </HeroBoxTop>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{ height: "280px", display: "flex", alignItems: "center" }}
              >
                <Box
                  component="img"
                  src="/assets/rivian.webp"
                  alt="riviant"
                  sx={{
                    width: "auto",
                    height: "492px",
                    position: "absolute",
                    right: "-112px",
                    bottom: 0,
                    zIndex: -1,
                    [theme.breakpoints.down("lg")]: {
                      height: "280px",
                      right: "50%",
                      transform: "translateX(50%)",
                    },
                  }}
                />
                <PlayButton videoID="XQrtn7GIEK0" channel="youtube" />
              </Box>
            </Grid>
          </Grid>
        </HeroBox>
      </Container>
    </Box>
  );
};

export default CaseStudies;
