import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HeadingTwo = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto Condensed",
  fontSize: "2.5rem",
  lineHeight: "2.75rem",
  textTransform: "uppercase",
  fontStyle: "italic",
  fontWeight: 700,
  [theme.breakpoints.up("sm")]: {
    fontSize: "3rem",
    lineHeight: "3.25rem",
  },
}));

export const HeadingThree = styled(Typography)(() => ({
  fontFamily: "Roboto Condensed",
  fontSize: "1.75rem",
  lineHeight: "2rem",
  textTransform: "uppercase",
  fontStyle: "italic",
  fontWeight: 700,
  marginTop: "32px",
}));

export const HeadingFour = styled(Typography)(() => ({
  fontFamily: "Roboto Condensed",
  fontSize: "1.5rem",
  lineHeight: "2rem",
  textTransform: "uppercase",
  fontStyle: "italic",
  fontWeight: 700,
  marginTop: "24px",
  marginBottom: "16px",
}));
