import { Box, Typography, Container } from "@mui/material";
import React from "react";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";
import SolutionCard from "../components/VehicleSolutionCard/VehicleSolutionCard";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const VehicleSpecializedSolutions = () => {
  const theme = useTheme();
  return (
    <>
      <Container
        disableGutters={useMediaQuery(theme.breakpoints.down("md"))}
        maxWidth="xl"
      >
        <ModuleHeadline
          headline="Complex Challenges, Specialized Solutions"
          sx={{ my: 0, mt: { xs: 7, md: 9 }, mb: 4 }}
        />

        <Typography
          variant="body1"
          sx={{ margin: "auto", maxWidth: 864, textAlign: "center", pb: 3 }}
        >
          We provide long-term solutions that seamlessly adapt to your immediate
          and changing needs. We use historical data and analytics to provide
          personalized insights regarding onsite solutions, compliance, network
          design and more.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            margin: "auto",
            justifyContent: "center",
            maxWidth: 900,
            mb: { xs: 3, md: 6 },
          }}
        >
          <SolutionCard
            headline="OEM"
            details="We serve a majority of OEMs across North America at port, rail, and/or manufacturing facilities."
          />
          <SolutionCard
            headline="Remarketing"
            details="RPM provides end to end transportation solutions across a variety of vehicle classes to support remarketers. We ensure vehicles reach the optimal sales channel through a diverse carrier network, through PQ and a proprietary vehicle age management process."
          />
          <SolutionCard
            headline="Retail"
            details="Everything from auction buys to shipping a new customer their big purchase, RPMs diverse carrier network allows retail clients to meet their customers where they are; driveway, dealership or anywhere in between."
          />
          <SolutionCard
            headline="Fleet Management"
            details="Whether infleeting or defleeting; RPM works with fleet clients to develop transportation solutions that balance flexibility, speed, and cost to ensure vehicles are handed to customers or delivered to remarketing channels with care."
          />
          <SolutionCard
            headline="Rental"
            details="Shifting demand, cycling of inventory and a volatile new vehicle market present challenges. Our network and responsive capacity allow rental companies to reposition inventory at a moment's notice to address seasonal shifts."
          />
          <SolutionCard
            headline="POV"
            details="While RPM handles single moves for dealerships, RPM Moves, RPM's B2C service, provides door-to-door transport for the end consumer."
            linkText="More about RPM Moves"
            linkUrl="/individual"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            padding: "36px 0",
            alignItems: { xs: "center", lg: "top" },
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            component="img"
            src="/assets/blue-map.webp"
            alt="map"
            sx={{
              width: "100%",
              maxWidth: "814px",
              height: "auto",
            }}
          />
          <Box sx={{ maxWidth: { xs: "100%", lg: "434px" }, py: 5, px: 2 }}>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
              }}
            >
              We understand our customers' needs at a micro level
            </Typography>
            <Typography variant="body1" sx={{ py: 3 }}>
              With shifting consumer behavior and the increase of online vehicle
              purchases, customers must meet consumer expectations of where a
              vehicle is in transit. RPM aggregates data and provides shipping
              visibility for its customer to inform their end consumer.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default VehicleSpecializedSolutions;
