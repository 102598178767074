import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import StatBox from "../StatBox/StatBox";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

const StatsModule = (props) => {
  return (
    <Container sx={{ py: 4 }} maxWidth="xxl">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Fade left>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
              textAlign: "center",
              py: 3,
              maxWidth: "870px",
            }}
          >
            {props.headline}
          </Typography>
        </Fade>
        {props.subHeadline && (
          <Fade bottom>
            <Typography
              variant="body1"
              sx={{ margin: "auto", maxWidth: 864, textAlign: "center", pb: 3 }}
            >
              {props.subHeadline}
            </Typography>
          </Fade>
        )}
      </Box>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        {props.stats.map((stat, index) => (
          <Grid
            key={index}
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: { xs: "center", sm: "center" },
              my: 2,
              mx: 5,
            }}
          >
            <Flip bottom delay={index * 400}>
              <StatBox
                label={stat.label}
                footer={stat.footer}
                emphasis={stat.emphasis}
              />
            </Flip>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default StatsModule;
